/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Stack,
  Typography,
  Grid,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { storage } from '../../firebase-setup/firebase';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { useCallback, useEffect, useState } from 'react';
import EditableMessages from './EditibleSMS';

function SMSHub() {
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [smsFiles, setSmsFiles] = useState<
    {
      fileName: string;
      displayName: string;
      content: any;
    }[]
  >([]);

  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const getFilesFromStorage = useCallback(async () => {
    setLoadingFiles(true);
    try {
      const storageRef = ref(storage, `/smsFiles`);
      const files = await listAll(storageRef);

      const smsFilesPromises = files.items.map(async (fileRef) => {
        try {
          // Get download URL for the file
          const fileUrl = await getDownloadURL(fileRef);
          console.log(fileUrl);
          // Fetch and parse JSON content
          const response = await fetch(fileUrl);
          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }
          const data = await response.json();

          return {
            fileName: fileRef.name, // File name for reference
            displayName: fileRef.name
              .split('.')[0]
              .split('sms')[1]
              .replace(/([A-Z])/g, ' $1')
              .trim(), // Display name for UI
            content: data, // Parsed JSON content
          };
        } catch (error) {
          console.error(
            `Error fetching file content for ${fileRef.name}:`,
            error
          );
          return null; // Skip this file if an error occurs
        }
      });

      // Wait for all file contents to be fetched
      const smsFiles = (await Promise.all(smsFilesPromises)).filter(Boolean); // Filter out null values

      setSmsFiles(smsFiles as any);
    } catch (error) {
      console.error('Error fetching files from storage:', error);
    } finally {
      setLoadingFiles(false);
    }
  }, []);
  const handleFileChange = (event: SelectChangeEvent) => {
    setSelectedFile(event.target.value as string);
  };

  useEffect(() => {
    if (smsFiles.length === 0) getFilesFromStorage();
  }, [getFilesFromStorage, smsFiles.length]);

  return !loadingFiles ? (
    <Stack
      className="container"
      sx={{
        mt: 2,
      }}>
      <Stack spacing={2} justifyContent={'center'} alignItems={'center'} mt={6}>
        <Typography variant="h2">SMS Editor</Typography>
        <Grid
          container
          spacing={2}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Grid item sm={9}>
            <InputLabel id="flow-selector">Select Flow</InputLabel>
            <Select
              fullWidth
              labelId="flow-selector"
              id="flow-selector"
              value={selectedFile || ''}
              label="Age"
              onChange={handleFileChange}>
              {smsFiles.map((file, index) => {
                return (
                  <MenuItem key={index} value={file.fileName}>
                    {file.displayName}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <EditableMessages selectedFile={selectedFile} smsFiles={smsFiles} />
      </Stack>
    </Stack>
  ) : (
    <div className="loader-s">
      <CircularProgress />
    </div>
  );
}
export default SMSHub;
