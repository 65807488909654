/* eslint-disable no-mixed-spaces-and-tabs */
import '../../App.css';
import './ComponenetsStyles/HealthcareProviders.css';
import { useState, useContext, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { healthcareDataState, userDataState } from '../../main';
import {
  deleteDocSF,
  formatPhoneNumber,
  navigateWithQuery,
} from '../../utils/utilFuntions';
import { updateDoctor } from '../../utils/sfFunctions';
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../../firebase-setup/firebase';
import ConfirmationIcon from '../../assets/images/ConfirmationIcon.svg';
import React from 'react';
import {
  Button,
  Drawer,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AutocompleteField from '../applicationForm/AutoCompleteField';
import { docInfo } from '../../utils/types';
import { sendEmail } from '../../utils/utilFuntions';

function HealthcareProviders() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const { healthcareData, setHealthcareData } = useContext(healthcareDataState);
  const { userData } = useContext(userDataState);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [newHealthcareData, setNewHealthcareData] = useState<docInfo[]>([]);
  const [openRemoveDocPopup, setOpenRemoveDocPopup] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [currentDoc, setCurrentDoc] = useState<number>(0);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  useEffect(() => {
    if (!userData || !healthcareData) return;
    if (healthcareData.length === 0 && userData && userData.noMeds) {
      setNewHealthcareData([
        {
          fname: '',
          mname: '',
          lname: '',
          phone: '',
          fax: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          country: 'USA',
          facility: '',
          faxValidate: '',
          fullAddress: '',
          suite: '',
        },
      ]);
      return;
    } else {
      // Create a deep copy of each object in healthcareData to avoid reference issues
      setNewHealthcareData(
        healthcareData
          ? healthcareData.map((doc) => ({ ...doc } as docInfo))
          : []
      );
    }
  }, [healthcareData, userData]);

  const removeHealthcareProvider = async (index: number) => {
    if (!healthcareData || !userData) return;
    const userDocRef = doc(db, 'clients', userData!.ssn.toString());
    const healthcareRef = collection(userDocRef, 'healthcare-providers');
    if (healthcareData[index]) {
      const q = query(
        healthcareRef,
        where('fax', '==', healthcareData[index].fax)
      );
      await getDocs(q).then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          deleteDoc(doc.ref);
        });
      });
      await deleteDocSF(
        healthcareData![index].fax,
        healthcareData![index].lname
      );
      setHealthcareData((prevData) => {
        const data = [...prevData!];
        data[index] = {
          fname: '',
          mname: '',
          lname: '',
          phone: '',
          fax: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          facility: '',
        };
        return data;
      });
      setNewHealthcareData((prevData) =>
        prevData.filter((_, i) => i !== index)
      );
      setOpenRemoveDocPopup(false);
    } else {
      setNewHealthcareData((prevData) =>
        prevData.filter((_, i) => i !== index)
      );
    }
  };
  async function updateHealthcareInfo() {
    if (userData === null || healthcareData === null || isDataUpdated) return;
    setIsUpdatingData(true);
    const hpInfo = `
          ${newHealthcareData.map(
            (doc) => `
             First Name: ${doc.fname}
             Middle Name: ${doc.mname}
             Last Name:${doc.lname}
             Facility: ${doc.facility}
             Phone: ${doc.phone}
             Fax: ${doc.fax}
             Address: ${doc.address} ${doc.suite} ,${doc.city}, ${doc.state}, ${doc.zip}
            `
          )}`;

    const emailData = {
      firstName: userData.fname,
      lastName: userData.lname,
      clientEmail: userData.email,
      hpInfo: hpInfo,
    };

    for (let i = 0; i < newHealthcareData.length; i++) {
      const userDocRef = doc(db, 'clients', userData.ssn.toString());
      const healthcareRef = collection(userDocRef, 'healthcare-providers');

      if (healthcareData[i]) {
        const q = query(
          healthcareRef,
          where('fax', '==', healthcareData[i].fax)
        );

        await getDocs(q).then((querySnapshot) => {
          const queryRef = doc(
            userDocRef,
            'healthcare-providers',
            querySnapshot.docs[0].id
          );
          if (querySnapshot.docs.length === 0) {
            addDoc(healthcareRef, newHealthcareData[i]);
          } else {
            setDoc(queryRef, newHealthcareData[i], {
              merge: true,
            });
          }
        });
      } else {
        addDoc(healthcareRef, newHealthcareData[i]);
      }

      await updateDoctor(newHealthcareData[i]);
      setHealthcareData((prevData) => [
        { ...prevData![i], ...newHealthcareData[i] },
      ]);
    }
    await sendEmail(emailData, 'template_qrmoi9v');
    setIsUpdatingData(false);
    setIsDataUpdated(true);
  }
  // Function to check if all required fields in all providers are filled
  const checkFormValidity = useCallback(() => {
    let isValid = true;

    // Loop through each healthcare provider
    newHealthcareData.forEach((provider, index) => {
      const requiredFields = [
        'fname',
        'lname',
        'facility',
        'phone',
        'fax',
        'address',
        'zip',
      ];
      requiredFields.forEach((field) => {
        if (!provider[field as keyof docInfo] || errors[field + index]) {
          isValid = false;
        }
      });
    });

    setIsFormValid(isValid);
  }, [newHealthcareData, errors]);

  useEffect(() => {
    let isUpdated = false;
    let isErrors = true;
    Object.keys(errors).forEach((key) => {
      if (errors[key] === '') {
        isErrors = false;
      }
    });
    if (!isErrors) {
      newHealthcareData.forEach((doc, index) => {
        if (JSON.stringify(doc) !== JSON.stringify(healthcareData![index])) {
          isUpdated = true;
        }
      });
    }
    setIsDataChanged(isUpdated);
  }, [newHealthcareData, healthcareData, errors]);

  // Centralized validation function
  const validateFields = useCallback((name: string, value: string) => {
    let error = '';
    if (name === 'phone' && !/^\(\d{3}\) \d{3} - \d{4}$/.test(value)) {
      error = 'Invalid phone number';
    } else if (name === 'fax' && !/^\(\d{3}\) \d{3} - \d{4}$/.test(value)) {
      error = 'Invalid fax number';
    } else if (name === 'zip' && !/^\d{5}$/.test(value)) {
      error = 'Invalid Zip Code';
    } else if (name === 'address' && value === '') {
      error = 'Invalid Address';
    }
    return error;
  }, []);

  const handleChangeWithValidation = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const fieldName = event.target.name;
      let fieldValue = event.target.value;
      if (fieldName === 'phone' || fieldName === 'fax') {
        fieldValue = formatPhoneNumber(event);
      }
      if (fieldName === 'zip') {
        fieldValue = fieldValue.slice(0, 5);
      }

      // Perform validation
      const error = validateFields(fieldName, fieldValue);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName + index]: error,
      }));

      // Update the healthcare data
      setNewHealthcareData((prevData) => {
        const data = [...prevData];
        data[index] = { ...data[index], [fieldName]: fieldValue };
        return data;
      });

      // Check form validity
      checkFormValidity();
    },
    [checkFormValidity, validateFields]
  );
  const renderTextField = (
    label: string,
    name: string,
    index: number,
    required = false
  ) => (
    <TextField
      label={label}
      name={name}
      required={required}
      placeholder={
        index >= newHealthcareData.length
          ? ''
          : newHealthcareData[index][name as keyof docInfo] || ''
      }
      value={
        index >= newHealthcareData.length
          ? ''
          : newHealthcareData[index][name as keyof docInfo] || ''
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeWithValidation(e, index);
        if (name === 'zip') {
          e.target.value = e.target.value.slice(0, 5);
        }
      }}
      error={!!errors[name + index]}
      helperText={errors[name + index]}
      margin="normal"
      fullWidth
    />
  );
  const addHealthcareProvider = () => {
    setNewHealthcareData((prevData) => [
      ...prevData,
      {
        fname: '',
        mname: '',
        lname: '',
        phone: '',
        fax: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        facility: '',
        faxValidate: '',
        fullAddress: '',
        suite: '',
      },
    ]);
  };

  // Call checkFormValidity whenever newHealthcareData or errors change
  useEffect(() => {
    checkFormValidity();
  }, [newHealthcareData, errors, checkFormValidity]);
  return (
    <>
      <Stack className="healthcare-s">
        <Stack className="container">
          <button
            className="back-btn"
            onClick={() => {
              navigateWithQuery(navigate, '/', location);
            }}>
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.6049 5.18512H2.85895L5.63995 7.96612L4.82095 8.78512L0.563945 4.52812L4.82095 0.27112L5.63995 1.09012L2.86795 3.86212H13.6049V5.18512Z"
                fill="#191919"
              />
            </svg>
            Back
          </button>
          {isUpdatingData ? (
            <Stack className="loader-c">
              <Stack className="loader" />
            </Stack>
          ) : isDataUpdated ? (
            <>
              <Stack className="success-c">
                <img src={ConfirmationIcon} alt="success" />
                <h2>Healthcare Providers data has been updated</h2>
              </Stack>
            </>
          ) : (
            <>
              <h1 className="text-center margin-bottom-64">
                Healthcare Providers Information
              </h1>

              <Stack className="healthcare-info-c">
                {newHealthcareData &&
                  newHealthcareData.map((_, index) => (
                    <React.Fragment>
                      <Grid container className="personal-info-c tprx-card">
                        <Grid item xs={12}>
                          <Stack
                            className="inputfields-row"
                            direction={{ xs: 'column', sm: 'row' }}>
                            {renderTextField(
                              'First Name',
                              'fname',
                              index,
                              true
                            )}
                            {renderTextField('Middle Name', 'mname', index)}
                            {renderTextField('Last Name', 'lname', index, true)}
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            className="inputfields-row"
                            direction={{ xs: 'column', sm: 'row' }}>
                            {renderTextField(
                              'Facility Name',
                              'facility',
                              index,
                              true
                            )}
                            {renderTextField(
                              'Phone Number',
                              'phone',
                              index,
                              true
                            )}
                            {renderTextField('Fax', 'fax', index, true)}
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            className="inputfields-row"
                            direction={{ xs: 'column', sm: 'row' }}>
                            <AutocompleteField
                              label={'Address'}
                              name={`fullAddress${index}`}
                              value={
                                index >= newHealthcareData.length
                                  ? ''
                                  : newHealthcareData[index].fullAddress || ''
                              }
                              onChange={(addressValues) => {
                                Object.keys(addressValues).forEach((name) => {
                                  const value =
                                    addressValues[name as keyof docInfo];
                                  handleChangeWithValidation(
                                    {
                                      target: { name, value },
                                    } as unknown as React.ChangeEvent<HTMLInputElement>,
                                    index
                                  );
                                });
                              }}
                              errors={errors[`fullAddress${index}`]}
                              setErrors={setErrors}
                              errorObjectName={`fullAddress${index}`}
                            />
                            <Stack direction={'row'} gap={2}>
                              {renderTextField('Suite', 'suite', index, false)}
                              {renderTextField('Zip', 'zip', index, true)}
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            className="inputfields-row"
                            direction={{ xs: 'column', sm: 'row' }}>
                            {index === newHealthcareData.length - 1 && (
                              <Button
                                variant="contained"
                                className="cta-main"
                                onClick={addHealthcareProvider}>
                                I have another Healthcare Provider
                              </Button>
                            )}
                            {index > 0 && (
                              <Button
                                variant="outlined"
                                className="cta-main"
                                onClick={() => {
                                  setCurrentDoc(index);
                                  newHealthcareData[index].fname === ''
                                    ? removeHealthcareProvider(index)
                                    : setOpenRemoveDocPopup(true);
                                }}>
                                Remove Healthcare Provider
                              </Button>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                <Stack className="save-btn-c">
                  <Button
                    variant="contained"
                    className="cta-main save-btn"
                    disabled={isDataChanged && isFormValid ? false : true}
                    onClick={async () => {
                      updateHealthcareInfo();
                    }}>
                    Update
                  </Button>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <Drawer open={openRemoveDocPopup}>
        <Stack className="remove-doc-popup">
          <Stack className="tprx-card">
            {newHealthcareData[currentDoc - 1] ? (
              <>
                <Typography variant="h2">Remove Doctor</Typography>
                <Typography variant="h3" className="text-center">
                  Are you sure you want to remove this doctor?<br></br> This
                  action can't be reversed
                </Typography>
                <Stack className="order-verification-btn-c">
                  <Button
                    variant="contained"
                    className="cta-main"
                    onClick={() => {
                      removeHealthcareProvider(currentDoc);
                      setOpenRemoveDocPopup(false);
                    }}>
                    Remove
                  </Button>
                  <Button
                    variant="contained"
                    className="cta-main secondary-btn"
                    onClick={() => {
                      setOpenRemoveDocPopup(false);
                    }}>
                    Cancel
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                <h2>You must have at least one doctor listed</h2>
                <h3 className="text-center">
                  If you want to remove this doctor, please change its details
                </h3>
                <button
                  className="cta-main"
                  onClick={() => {
                    setOpenRemoveDocPopup(false);
                  }}>
                  I understand
                </button>
              </>
            )}
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
export default HealthcareProviders;
