import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  styled,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { deleteObject, StorageReference } from 'firebase/storage';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileUploader = memo(function FileUploader({
  clientFiles,
  docName,
  docFax,
  uploaderId,
  onFilesAdded,
  onFileRemove,
  reset,
  onResetComplete,
  onValidationStateChange,
  getFilesFromStorage,
}: {
  clientFiles: { file: StorageReference; emailName: string; docName: string }[];
  docName: string;
  docFax: number;
  uploaderId: string;
  onFilesAdded: (
    hpName: string,
    hpFax: number,
    id: string,
    files: File[]
  ) => void;
  onFileRemove: (index: number) => void;
  reset: boolean;
  onResetComplete: () => void;
  onValidationStateChange: (state: boolean) => void;
  getFilesFromStorage: () => Promise<void>;
}) {
  const [files, setFiles] = useState<(StorageReference | File)[]>([]);
  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const uploadedFiles = Array.from(event.target.files);
        setFiles(uploadedFiles);
        onFilesAdded(docName, docFax, uploaderId, uploadedFiles);
        const isValid = uploadedFiles.length > 0;
        onValidationStateChange(isValid);
      }
    },
    [onFilesAdded, docName, docFax, uploaderId, onValidationStateChange]
  );

  const handleFileRemove = useCallback(
    async (index: number) => {
      if (clientFiles.length > 0) {
        console.log('clientFiles', clientFiles);
        const currentFile = clientFiles[index].file;
        await deleteObject(currentFile);
        getFilesFromStorage();
      } else {
        onFileRemove(index);
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      }
      onValidationStateChange(false);
    },
    [clientFiles, getFilesFromStorage, onFileRemove, onValidationStateChange]
  );

  useEffect(() => {
    if (reset && files.length > 0) {
      setFiles([]);
      onResetComplete();
    }
  }, [reset, onResetComplete, files.length]);

  useEffect(() => {
    if (clientFiles.length > 0) {
      const fileList = clientFiles.map((fileObject) => fileObject.file);
      setFiles((prevFiles) => {
        const newFiles = fileList.filter(
          (newFile) => !prevFiles.some((file) => file.name === newFile.name)
        );
        return [...prevFiles, ...newFiles];
      });
    }
  }, [clientFiles, onValidationStateChange]); // Empty dependency array to run only once

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
      }}>
      <Button
        sx={{
          pt: 1,
          pb: 1,
          pl: 2,
          pr: 2,
        }}
        size="small"
        variant="contained"
        component="label"
        startIcon={<UploadFileIcon />}>
        Upload
        <VisuallyHiddenInput
          type="file"
          hidden
          multiple
          onChange={(event) => {
            const maxSize = 2 * 1024 * 1024; // 2MB
            const files = event.target.files;
            if (files) {
              const totalSize = Array.from(files).reduce(
                (acc, file) => acc + file.size,
                0
              );
              if (totalSize > maxSize) {
                alert(
                  'Total file size exceeds the maximum allowed size of 2 MB.'
                );
                return;
              }

              handleFileChange(event);
            }
          }}
          accept=".pdf" // Adjust accepted file types as needed
        />
      </Button>

      {files.length > 0 && (
        <>
          <List
            sx={{
              marginTop: 2,
              padding: 0,
            }}>
            {files.map((file, index) => (
              <ListItem
                sx={{
                  p: 1,
                  m: 0,
                }}
                key={file.name + index}
                secondaryAction={
                  <IconButton
                    size="small"
                    color="error"
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleFileRemove(index)}>
                    <DeleteIcon />
                  </IconButton>
                }>
                <ListItemText
                  secondary={
                    file.name.length > 24
                      ? `${file.name.slice(0, 24)}...`
                      : file.name
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
});

export default FileUploader;
