import { PropsWithChildren, useState } from 'react';
// import logo from '../../assets/images/full logo.png';
import '../../index.css';
// import { signOut } from 'firebase/auth';
// import { auth } from '../../firebase-setup/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useFA } from '../Login/TwoFA';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  //   Stack,
} from '@mui/material';
import { navigateWithQuery } from '../../utils/utilFuntions';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const AdminNav: React.FC<PropsWithChildren> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  //   const { setValidFactor } = useFA();
  //   const handleLogout = () => {
  //     signOut(auth)
  //       .then(() => {
  //         setValidFactor(false);
  //         // Sign-out successful.
  //         navigate('/');
  //       })
  //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //       .catch((error) => {
  //         console.log(error);
  //         // An error happened.
  //       });
  //   };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const navigationItems = [
    { text: 'Home', icon: <HomeIcon />, link: '/admin' },
    { text: 'About', icon: <InfoIcon />, link: '/admin/fax' },
    { text: 'Contact', icon: <ContactMailIcon />, link: '/admin/sms' },
  ];
  return (
    <>
      {/* Menu Icon to toggle drawer */}
      <IconButton
        onClick={toggleDrawer}
        sx={{ position: 'fixed', top: 10, left: 10 }}>
        <MenuIcon />
      </IconButton>

      {/* Sidebar Drawer */}
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer}>
        <div style={{ width: 250 }}>
          {/* Header with Close Icon */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 16px',
            }}>
            <h3>Menu</h3>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />

          {/* Navigation List */}
          <List>
            {navigationItems.map((item, index) => (
              <ListItem
                key={index}
                onClick={() =>
                  navigateWithQuery(navigate, `${item.link}`, location)
                }>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      {props.children}
    </>
  );
};
export default AdminNav;
