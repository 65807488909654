import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import {
  collection,
  DocumentData,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import {
  Alert,
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  Snackbar,
  Stack,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import FaxUploaderComponenet from './FaxUploaderComponenet';
import { storage } from '../../firebase-setup/firebase';
import {
  ref,
  uploadBytes,
  listAll,
  getMetadata,
  StorageReference,
} from 'firebase/storage';
import { LoopsClient } from 'loops';
const FaxUploader = memo(function FaxUploader() {
  const [clientsData, loadingClientsData] = useCollection(
    collection(db, 'clients'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [loadingFiles, setLoadingFiles] = useState(false);

  const [meds, loadingMeds] = useCollection(collection(db, 'medications'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [tasks, loadingTasks] = useCollection(collection(db, 'tasks'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [brandMeds, setBrandMeds] = useState<string[]>([]);

  const [clientList, setClientList] = useState<string[]>([]);
  const [clientRelatedTasks, setClientRelatedTasks] = useState<
    DocumentData[] | null
  >(null);
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [selectClientName, setSelectClientName] = useState<string>('');
  const [selectClientId, setSelectedClientId] = useState<string>('');
  const [selectedClientJoinDate, setSelectedClientJoinDate] =
    useState<string>('');
  const [hpList, setHpList] = useState<
    { docName: string; docFax: number; isAutomationActive: string }[]
  >([]);
  const [ordersList, setOrdersList] = useState<
    { docName: string; medication: string }[]
  >([]);

  const [allFiles, setAllFiles] = useState<{
    [key: string]: { files: File[]; id: string; hpFax: number }[];
  }>({});
  // const [isValid, setIsValid] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<
    'success' | 'error' | 'loading' | null
  >(null);
  const [resetFlag, setResetFlag] = useState(false);
  const [clientFiles, setClientFiles] = useState<
    {
      file: StorageReference;
      emailName: string;
      docName: string;
    }[]
  >([]);

  const [fileUploadedState, setFileUploadedState] = useState<{
    [docName: string]: {
      [uploaderId: string]: boolean;
    };
  }>({});
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
  });
  const getClientHpList = useCallback(
    async (clientDoc: DocumentData) => {
      const hpCollectionRef = collection(
        db,
        `clients/${clientDoc.id}/healthcare-providers`
      );
      const hpSnapshot = await getDocs(hpCollectionRef);
      const hpList = hpSnapshot.docs
        .map((doc) => ({
          docName:
            doc.data().mname === '' || doc.data().mname === ' '
              ? doc.data().fname + ' ' + doc.data().lname
              : doc.data().fname +
                ' ' +
                doc.data().mname +
                ' ' +
                doc.data().lname,
          docFax: Number(doc.data().fax.replace(/\D/g, '')),
          isAutomationActive: doc.data().automation,
        }))
        .filter((hp) => hp.docName !== '' && hp.docName !== ' ');
      const initFilesData = hpList.reduce<
        Record<
          string,
          {
            welcomeEmail: boolean;
            firstFax: boolean;
            faxReminder: boolean;
          }
        >
      >((acc, hp) => {
        if (!hp.docName) {
          console.error('Invalid hp.docName:', hp);
          return acc; // Skip this iteration if docName is missing or invalid
        }
        console.log('clientFiles', clientFiles);
        acc[hp.docName] = {
          welcomeEmail:
            clientFiles?.some(
              (file) =>
                file.docName === hp.docName && file.emailName === 'welcomeEmail'
            ) || false,
          firstFax:
            clientFiles?.some(
              (file) =>
                file.docName === hp.docName && file.emailName === 'firstFax'
            ) || false,
          faxReminder:
            clientFiles?.some(
              (file) =>
                file.docName === hp.docName && file.emailName === 'faxReminder'
            ) || false,
        };
        return acc;
      }, {});
      console.log('initFilesData', initFilesData);
      setFileUploadedState(initFilesData);
      setHpList(hpList);
    },
    [clientFiles]
  );

  const getClientOrderList = useCallback(async (clientDoc: DocumentData) => {
    const orderCollectionRef = collection(db, `clients/${clientDoc.id}/orders`);
    const orderSnapshot = await getDocs(orderCollectionRef);
    const orderList = orderSnapshot.docs.map((doc) => {
      return {
        docName: doc.data().doctorName,
        medication: doc.data().medicationName,
      };
    });
    setOrdersList(orderList);
  }, []);

  const getFilesFromStorage = useCallback(async (clientName: string) => {
    setLoadingFiles(true);
    try {
      const storageRef = ref(storage, `/clientFiles/${clientName}`);
      const files = await listAll(storageRef);
      const filesMetadata = files.items.map(async (fileRef) => {
        const metadata = await getMetadata(fileRef);
        return metadata.customMetadata;
      });
      const filesMetadataResolved = await Promise.all(filesMetadata);
      const clientFiles = files.items.map((fileRef, index) => {
        return {
          file: fileRef,
          emailName: filesMetadataResolved[index]!.emailName,
          docName: filesMetadataResolved[index]!.docName,
        };
      });
      console.log('clientFiles', clientFiles);
      setClientFiles(clientFiles);
    } catch (error) {
      console.error('Error fetching files from storage:', error);
    } finally {
      setLoadingFiles(false);
    }
  }, []);

  const initAutomation = useCallback(
    async (faxes: { files: File[]; id: string; hpFax: number }[]) => {
      const tasks = faxes.map((fax) => ({
        filesPath: fax.files.map((file) => `${selectClientName}/${file.name}`),
        id: fax.id,
        faxNumber: fax.hpFax,
        clientJoinDate: selectedClientJoinDate,
        clientId: selectClientId,
      }));
      try {
        const response = await fetch(
          'https://us-central1-transparent-rx.cloudfunctions.net/faxSenderScheduler',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tasks }),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to schedule batch tasks');
        }

        const data = await response.json();
        console.log('Batch tasks scheduled:', data);
      } catch (error) {
        console.error('Error scheduling batch tasks:', error);
      }
    },
    [selectClientId, selectClientName, selectedClientJoinDate]
  );

  const handleClientChange = useCallback(
    async (value: string) => {
      if (!clientsData) return;
      setSelectedClient(value);
      setSelectClientName(value.replace(/\s+/g, '_').toLowerCase());
      const clientDoc = clientsData!.docs.find((doc) => {
        const data = doc.data();
        return `${data.fname} ${data.lname} (${data.email})` === value;
      });
      if (clientDoc) {
        const clientTasks = tasks!.docs
          .filter((doc) => {
            return (
              doc.data().taskFiles[0].split('/')[0] ===
              value.replace(/\s+/g, '_').toLowerCase()
            );
          })
          .map((doc) => doc.data());
        setSelectedClientId(clientDoc.data().ssn);
        setClientRelatedTasks(clientTasks || null);
        const joinDate = new Date(clientDoc.data().timestamp.seconds * 1000); // Convert to milliseconds
        setSelectedClientJoinDate(joinDate.toISOString().split('T')[0]);
        getClientHpList(clientDoc);
        getClientOrderList(clientDoc);
        getFilesFromStorage(value.replace(/\s+/g, '_').toLowerCase());
      } else {
        setHpList([]);
        setOrdersList([]);
        setClientFiles([]);
        setClientRelatedTasks(null);
      }
    },
    [
      clientsData,
      getClientHpList,
      getClientOrderList,
      getFilesFromStorage,
      tasks,
    ]
  );

  const medicationType = useCallback(
    (orders: string[], brandMeds: string[]) => {
      let segment = '';
      orders.forEach((order) => {
        if (!order || order === '') return;
        const fixedMedName = order.slice(0, order.indexOf(' ('));
        if (
          brandMeds.find((med) => med === fixedMedName) ||
          brandMeds.find((med) => med === fixedMedName.split(' ')[0])
        ) {
          if (segment === 'Generic') {
            segment = 'Both';
            return;
          }
          segment = 'Brand';
          return;
        } else {
          if (segment === 'Brand' || segment === 'Both') {
            segment = 'Both';
            return;
          }
          segment = 'Generic';
        }
      });
      return segment;
    },
    []
  );
  const isAutomationReady = useCallback(
    (hp: { docName: string | number }) => {
      return (
        fileUploadedState[hp.docName]?.welcomeEmail &&
        fileUploadedState[hp.docName]?.firstFax &&
        fileUploadedState[hp.docName]?.faxReminder
      );
    },
    [fileUploadedState]
  );
  const createTableData = useCallback(
    (
      hpList: {
        docName: string;
        docFax: number;
        isAutomationActive: string;
      }[],
      ordersList: { docName: string; medication: string }[]
    ) => {
      const rows: {
        hp: {
          docName: string;
          docFax: number;
          isAutomationActive: string;
          isAutomationReady: boolean;
        };
        type: string;
        faxes: {
          required: boolean;
          name: string;
        }[];
      }[] = [];

      hpList.forEach((hp) => {
        const hpMeds = ordersList
          .map((order) => {
            if (order.docName === hp.docName) return order.medication;
          })
          .filter((med): med is string => med !== undefined);
        const type = medicationType(hpMeds, brandMeds);
        const row = {
          hp: {
            docName: hp.docName,
            docFax: hp.docFax,
            isAutomationActive: hp.isAutomationActive || 'stopped',
            isAutomationReady: isAutomationReady(hp),
          },
          type: type,
          faxes: [
            {
              required: !clientFiles.some(
                (file) =>
                  file.docName === hp.docName &&
                  file.emailName === 'welcomeEmail'
              ),
              name: 'welcomeEmail',
            },
            {
              required: !clientFiles.some(
                (file) =>
                  file.docName === hp.docName && file.emailName === 'firstFax'
              ),
              name: 'firstFax',
            },
            {
              required: !clientFiles.some(
                (file) =>
                  file.docName === hp.docName &&
                  file.emailName === 'faxReminder'
              ),
              name: 'faxReminder',
            },
          ],
        };
        rows.push(row);
      });
      console.log('rows', rows);
      return rows;
    },
    [brandMeds, clientFiles, isAutomationReady, medicationType]
  );

  const handleFilesAdded = useCallback(
    (hpName: string, hpFax: number, id: string, files: File[]) => {
      console.log('files', files);
      setAllFiles((prev) => {
        const updatedFiles = { ...prev };
        if (!updatedFiles[hpName]) {
          updatedFiles[hpName] = [];
        }
        updatedFiles[hpName].push({ files, id, hpFax });
        return updatedFiles;
      });
    },
    []
  );
  const handleFileRemove = useCallback(
    (hpName: string, index: number) => {
      const updatedFiles = [...allFiles[hpName]];
      updatedFiles.splice(index, 1);
      setAllFiles((prev) => ({
        ...prev,
        [hpName]: updatedFiles,
      }));
    },
    [allFiles]
  );

  const uploadFile = useCallback(
    async (file: File, emailName: string, clientName: string) => {
      const storageRef = ref(
        storage,
        `/clientFiles/${clientName}/` + file.name
      );

      const docName = Object.keys(allFiles).find((key) =>
        allFiles[key].some((fileObj) => fileObj.files.includes(file))
      );

      if (!docName) {
        throw new Error(`Document name not found for file: ${file.name}`);
      }

      const metaData = {
        customMetadata: {
          emailName: emailName || '-',
          docName: docName || '-',
        },
      };
      try {
        await uploadBytes(storageRef, file, metaData);
        console.log(`File uploaded: ${file.name}`);
      } catch (error) {
        console.error(`Failed to upload file: ${file.name}`, error);
        throw error; // Propagate the error
      }
    },
    [allFiles]
  );

  const uploadFileGroup = useCallback(
    async (fileObject: { files: File[]; id: string }, clientName: string) => {
      try {
        const uploadPromises = fileObject.files.map((file) =>
          uploadFile(file, fileObject.id, clientName)
        );

        await Promise.all(uploadPromises); // Upload all files in parallel
      } catch (error) {
        console.error(`Error uploading file group ${fileObject.id}:`, error);
        throw error; // Rethrow to propagate error to the main handler
      }
    },
    [uploadFile]
  );

  const handleValidationStateChange = useCallback(
    (uploaderId: string, isValid: boolean, docName: string) => {
      // Check if the docName exists and the current state matches the intended state
      console.log('fileUploadedState', fileUploadedState);
      if (
        fileUploadedState[docName] &&
        fileUploadedState[docName][uploaderId] === isValid
      )
        return;

      setFileUploadedState((prev) => {
        // Ensure docName exists in the state
        const updatedDocState = prev[docName] || {}; // Initialize if undefined

        // Update only if there's an actual change
        if (updatedDocState[uploaderId] === isValid) return prev;

        return {
          ...prev,
          [docName]: {
            ...updatedDocState,
            [uploaderId]: isValid,
          },
        };
      });
    },
    [fileUploadedState]
  );
  const resetUploader = useCallback(() => {
    setAllFiles((prev) => {
      const updatedFiles = { ...prev };
      Object.keys(updatedFiles).forEach((key) => {
        updatedFiles[key] = [];
      });
      return updatedFiles;
    });
    // setIsValid(false);

    setResetFlag(true); // Trigger reset in child components
  }, []);
  const updateDocStatus = useCallback(
    async (docFax: string, status: string) => {
      const hpCollectionRef = collection(
        db,
        `clients/${selectClientId}/healthcare-providers`
      );
      const hpSnapshot = await getDocs(hpCollectionRef);
      const currentHp = hpSnapshot.docs.find((doc) => {
        return doc.data().fax.replace(/\D/g, '') === docFax.replace(/\D/g, '');
      });
      if (currentHp) {
        await updateDoc(currentHp.ref, { automation: status });
      } else {
        console.error('No healthcare provider found:', docFax);
      }
    },
    [selectClientId]
  );
  const handleUploadAllFiles = useCallback(
    async (faxNumber: number) => {
      console.log('allFiles', allFiles);
      const allFileLists = Object.values(allFiles).flat();
      const scheduledFaxes = allFileLists.filter(
        (file) => file.id !== 'welcomeEmail' && file.hpFax === faxNumber
      );
      if (allFileLists.length === 0) {
        console.error('No files to upload.');
        return;
      }

      try {
        setUploadStatus('loading'); // Set loading status
        const uploadTasks = allFileLists.map((fileObject) =>
          uploadFileGroup(fileObject, selectClientName)
        );

        await Promise.all(uploadTasks); // Wait for all uploads to complete
        setUploadStatus('success'); // Set success status
        resetUploader(); // Reset uploader after successful upload
        getFilesFromStorage(selectClientName); // Refresh file list
        initAutomation(scheduledFaxes);
        updateDocStatus(
          hpList.find((hp) => hp.docFax === faxNumber)!.docFax.toString(),
          'active'
        );
      } catch (error) {
        console.error('Error uploading files:', error);
        setUploadStatus('error'); // Set error status
      }
    },
    [
      allFiles,
      resetUploader,
      getFilesFromStorage,
      selectClientName,
      initAutomation,
      updateDocStatus,
      hpList,
      uploadFileGroup,
    ]
  );

  const handlCancleAutomation = useCallback(
    async (faxNumber: number, client: string) => {
      // get task related to the hp
      const hpTasks = clientRelatedTasks?.filter(
        (task) => task.taskTarget === faxNumber
      );
      const tasksId = hpTasks
        ?.filter((task) => {
          if (task.status === 'active') {
            return task.name.split('/').pop();
          }
        })
        .map((task) => task.name.split('/').pop());

      try {
        fetch(
          'http://127.0.0.1:5001/transparent-rx/us-central1/faxSenderCancel',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tasksId }),
          }
        );
      } catch (error) {
        console.error('Error scheduling batch tasks:', error);
      }

      updateDocStatus(
        hpList.find((hp) => hp.docFax === faxNumber)!.docFax.toString(),
        'stopped'
      );

      setHpList((prev) =>
        prev.map((hp) => {
          if (hp.docFax === faxNumber) {
            hp.isAutomationActive = 'stopped';
          }
          return hp;
        })
      );
      const loops = new LoopsClient(process.env.VITE_LOOPS_API!);

      // remove client from loops
      await loops.updateContact(`${client.split('(')[1].slice(0, -1)}`, {
        mailingLists: JSON.stringify({
          cm4u4x6qf007w0nkzae3bg92w: false,
          cm4u4xh3e00b60nk00g3a88p6: false,
          cm4u4zyb900ia0ml88ehj1hxr: false,
          cm4u504it00ew0kjogt789h1u: false,
        }),
      });
    },
    [clientRelatedTasks, hpList, updateDocStatus]
  );

  const handleResetComplete = useCallback(() => {
    setResetFlag(false); // Clear the reset flag after reset is applied
  }, []);
  const uploadStatusMessage = () => {
    return (
      <Snackbar
        open={!!uploadStatus}
        autoHideDuration={3000}
        onClose={() => setUploadStatus(null)} // Reset status on close
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={() => setUploadStatus(null)}
          severity={
            uploadStatus === 'success'
              ? 'success'
              : uploadStatus === 'loading'
              ? 'info'
              : 'error'
          }
          sx={{ width: '100%' }}>
          {uploadStatus === 'success'
            ? 'All files uploaded successfully!'
            : uploadStatus === 'loading'
            ? 'Uploading files...'
            : 'Error uploading files. Please try again.'}
        </Alert>
      </Snackbar>
    );
  };

  useEffect(() => {
    if (!loadingClientsData && clientsData) {
      const orderedList = clientsData!.docs.map((doc) => {
        if (
          doc.data().insurance.company === 'medicare' ||
          doc.data().employed === 'No Income in Household'
        )
          return (
            doc.data().fname +
            ' ' +
            doc.data().lname +
            ' (' +
            doc.data().email +
            ')'
          );
      });

      orderedList.sort((a, b) => {
        if (a! > b!) return 1;
        if (a! < b!) return -1;
        return 0;
      });
      setClientList(
        orderedList.filter((client) => client !== undefined) as string[]
      );
    }
  }, [loadingClientsData, clientsData]);
  useEffect(() => {
    if (!loadingMeds && meds) {
      meds.docs.map((doc) => {
        doc.data().brand && setBrandMeds((prev) => [...prev, doc.data().name]);
      });
    }
  }, [loadingMeds, meds]);

  const tableData = useMemo(() => {
    if (!hpList.length || !ordersList.length) return [];
    return createTableData(hpList, ordersList);
  }, [hpList, ordersList, createTableData]);

  return !loadingClientsData && !loadingTasks ? (
    <>
      <Stack
        className="container"
        sx={{
          mt: 2,
        }}>
        <Stack className="tprx-card">
          <Typography variant="h2">
            Clients Files Uploader (Medicare & No Income)
          </Typography>

          <Grid
            container
            spacing={2}
            width={'100%'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Grid item sm={9}>
              <FormControl
                id="client"
                sx={{
                  width: '100%',
                }}>
                <Autocomplete
                  filterOptions={filterOptions}
                  disablePortal
                  options={clientList}
                  value={selectedClient || null}
                  onChange={(_e, value) => {
                    handleClientChange(value as string);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      name={'client'}
                      value={selectedClient || null}
                      label={'Select Client'}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          {selectedClient ? (
            <TableContainer
              sx={{
                display: 'flex',
              }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width={'15%'}
                      sx={{
                        p: 2,
                      }}>
                      Doctor Name
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'10%'}
                      sx={{
                        p: 2,
                      }}>
                      Type
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'20%'}
                      sx={{
                        p: 2,
                      }}>
                      Welcome Email
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'20%'}
                      sx={{
                        p: 2,
                      }}>
                      First Fax
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'20%'}
                      sx={{
                        p: 2,
                      }}>
                      Fax Reminder
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'15%'}
                      sx={{
                        p: 2,
                      }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loadingFiles ? (
                    tableData?.map((row, index) => (
                      <TableRow
                        key={row.hp.docName + index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell
                          component="th"
                          scope="row"
                          width={'15%'}
                          sx={{
                            p: 2,
                          }}>
                          {row.hp.docName}
                        </TableCell>

                        <TableCell
                          align="center"
                          width={'10%'}
                          sx={{
                            p: 2,
                          }}>
                          {row.type}
                        </TableCell>
                        {row.faxes.map((fax, faxIndex) => (
                          <TableCell
                            align="center"
                            width={'20%'}
                            sx={{
                              p: 2,
                            }}
                            key={`${row.hp.docName}-${fax.name}-${faxIndex}`}>
                            <FaxUploaderComponenet
                              clientFiles={clientFiles.filter(
                                (fileObject) =>
                                  fileObject.emailName === fax.name &&
                                  fileObject.docName === row.hp.docName
                              )}
                              docName={row.hp.docName}
                              docFax={row.hp.docFax}
                              uploaderId={fax.name}
                              onFilesAdded={handleFilesAdded}
                              onFileRemove={(index: number) =>
                                handleFileRemove(row.hp.docName, index)
                              }
                              reset={resetFlag}
                              onResetComplete={handleResetComplete}
                              onValidationStateChange={(isValid: boolean) =>
                                handleValidationStateChange(
                                  fax.name,
                                  isValid,
                                  row.hp.docName
                                )
                              }
                              getFilesFromStorage={() =>
                                getFilesFromStorage(selectClientName)
                              }
                            />
                          </TableCell>
                        ))}
                        <TableCell
                          align="center"
                          width={'15%'}
                          sx={{
                            p: 2,
                          }}>
                          <Button
                            size="small"
                            sx={{ width: '100%' }}
                            variant="contained"
                            onClick={() => {
                              row.hp.isAutomationActive &&
                              !row.hp.isAutomationReady
                                ? handlCancleAutomation(
                                    row.hp.docFax,
                                    selectedClient
                                  )
                                : handleUploadAllFiles(row.hp.docFax);
                            }}
                            disabled={
                              (!row.hp.isAutomationReady &&
                                row.hp.isAutomationActive === 'stopped') ||
                              uploadStatus === 'loading'
                            }>
                            {row.hp.isAutomationActive === 'active'
                              ? 'Stop Automation'
                              : 'Start Automation'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        width={'100%'}
                        align="center"
                        colSpan={6}
                        sx={{
                          p: 2,
                          borderBottom: 'none',
                        }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="h4">
              Please select a client to view their files.
            </Typography>
          )}
        </Stack>
      </Stack>

      {uploadStatus && uploadStatusMessage()}
    </>
  ) : (
    <div className="loader-s">
      <CircularProgress />
    </div>
  );
});
export default FaxUploader;
