/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { formValues } from '../../../utils/types';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import QualificationDialog from '../QualificationDialog';
import { formatPhoneNumber } from '../../../utils/utilFuntions';

function MedicareFields({
  values,
  setValues,
  errors,
  requiredCheck,
  setValidStep,
  isMedicarePartD,
  setIsMedicarePartD,
  isMedicarePartC,
  setIsMedicarePartC,
  isSpouseOnSSC,
}: {
  values: formValues;
  setValues: React.Dispatch<React.SetStateAction<formValues>>;
  errors: { [key: string]: string };
  requiredCheck: (name: string, value: string) => void;
  setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
  isMedicarePartD: boolean;
  setIsMedicarePartD: React.Dispatch<React.SetStateAction<boolean>>;
  isMedicarePartC: boolean;
  setIsMedicarePartC: React.Dispatch<React.SetStateAction<boolean>>;
  isSpouseOnSSC: boolean;
}) {
  const [openCostDialog, setOpenCostDialog] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpenCostDialog(true);
  };

  const handleClose = () => {
    setOpenCostDialog(false);
    // setSelectedValue(value);
  };
  const qualificationDialogText = useRef<string>('without Medicare covarage');
  const handleMedicareChange = useCallback(
    (value: string | null, name: string) => {
      setValues((prev) => ({
        ...prev,
        patient: {
          ...prev.patient,
          medicare: {
            ...prev.patient.medicare,
            [name]: value,
          },
        },
      }));
    },
    [setValues]
  );
  return (
    <>
      {isSpouseOnSSC && (
        <Grid item xs={12} md={3} key={'SpouseSSC'}>
          <FormControl required>
            <FormLabel id="Are Both Spouses on Social Security Checks">
              Are Both Spouses on Social Security Checks?
            </FormLabel>
            <RadioGroup
              aria-labelledby="Are Both Spouses on Social Security Checks"
              name="bothSSC"
              sx={{ mt: 2 }}
              value={values.patient.medicare['bothSSC']}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'bothSSC');
                if (e.target.value === 'no') {
                  setValidStep(false);
                  (qualificationDialogText.current =
                    'Households with only one spouse on Social Security Checks'),
                    handleClickOpen();
                }
              }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} md={3} key={'medicare partD'}>
        <FormControl required>
          <FormLabel id="medicare partD">Are you on Medicare Part D?</FormLabel>
          <RadioGroup
            aria-labelledby="medicare partD"
            name="partD"
            sx={{ mt: 2 }}
            value={values.patient.medicare['partD']}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleMedicareChange(e.target.value, 'partD');
              if (e.target.value === 'no') {
                setIsMedicarePartD(false);
              } else {
                setIsMedicarePartD(true);
              }
            }}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>

      {!isMedicarePartD && (
        <Grid item xs={12} md={3} key={'medicare advantage'}>
          <FormControl required={!isMedicarePartD}>
            <FormLabel id="medicare advantage">
              Are you on Medicare Advantage - Part C
            </FormLabel>
            <RadioGroup
              aria-labelledby="medicare advantage"
              name="advantage"
              sx={{ mt: 2 }}
              value={values.patient.medicare['advantage']}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'advantage');
                e.target.value === 'yes'
                  ? setIsMedicarePartC(true)
                  : setIsMedicarePartC(false);
              }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      {!isMedicarePartD && isMedicarePartC && (
        <Grid item xs={12} md={4} key={'medicare advantage coverage'}>
          <FormControl required={isMedicarePartC}>
            <FormLabel id="medicare advantage coverage">
              Does your Medicare advantage have ANY Prescription Coverage for
              BRAND Medications? (Including copays, discounts, deductibles, and
              indemnity):
            </FormLabel>
            <RadioGroup
              aria-labelledby="medicare advantage coverage"
              name="advantageCoverage"
              sx={{ mt: 2 }}
              value={values.patient.medicare['advantageCoverage']}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'advantageCoverage');
              }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={isMedicarePartC && !isMedicarePartD ? 2 : 3}
        key={'Do you have Medicare Supplement'}>
        <FormControl required>
          <FormLabel id="Do you have Medicare Supplement">
            Do you have Medicare Supplement{' '}
          </FormLabel>
          <RadioGroup
            aria-labelledby="Do you have Medicare Supplement"
            name="supplement"
            sx={{ mt: 2 }}
            value={values.patient.medicare['supplement']}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleMedicareChange(e.target.value, 'supplement');
            }}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>

      {isMedicarePartD && (
        <>
          <Grid item xs={12} key={'fields title'}>
            <Typography variant="body1">
              If you currently don't have this information you can still proceed
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} key={'insurance name'}>
            <TextField
              error={errors['insuranceName'] ? true : false}
              helperText={errors['insuranceName']}
              key={'insurance name'}
              fullWidth
              name={'insuranceName'}
              value={values.patient.medicare['insuranceName'] || ''}
              label={'Insurance Company Name'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'insuranceName');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'insurance address'}>
            <TextField
              error={errors['insuranceAddress'] ? true : false}
              helperText={errors['insuranceAddress']}
              key={'insurance address'}
              fullWidth
              name={'insuranceAddress'}
              value={values.patient.medicare['insuranceAddress'] || ''}
              label={'Address of insurance Company'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'insuranceAddress');
                isMedicarePartD &&
                  requiredCheck('insuranceAddress', e.target.value);
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'insurance plan'}>
            <TextField
              error={errors['planName'] ? true : false}
              helperText={errors['planName']}
              key={'insurance plan'}
              fullWidth
              name={'planName'}
              value={values.patient.medicare['planName'] || ''}
              label={'Plan Name'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'planName');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'insurance plan phone'}>
            <TextField
              error={errors['planPhone'] ? true : false}
              helperText={errors['planPhone']}
              key={'insurance plan phone'}
              fullWidth
              name={'planPhone'}
              value={values.patient.medicare['planPhone'] || ''}
              label={'Plan Phone'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                formatPhoneNumber(e);
                handleMedicareChange(e.target.value, 'planPhone');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'plan group'}>
            <TextField
              error={errors['groupNumber'] ? true : false}
              helperText={errors['groupNumber']}
              key={'plan group'}
              fullWidth
              name={'groupNumber'}
              value={values.patient.medicare['groupNumber'] || ''}
              label={'Group#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'groupNumber');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'RxBIN'}>
            <TextField
              error={errors['rxbin'] ? true : false}
              helperText={errors['rxbin']}
              key={'RxBIN'}
              fullWidth
              name={'rxbin'}
              value={values.patient.medicare['rxbin'] || ''}
              label={'RxBIN#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'rxbin');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'RxPCN'}>
            <TextField
              error={errors['rxpcn'] ? true : false}
              helperText={errors['rxpcn']}
              key={'RxPCN'}
              fullWidth
              name={'rxpcn'}
              value={values.patient.medicare['rxpcn'] || ''}
              label={'RxPCN#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'rxpcn');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'RxGroup'}>
            <TextField
              error={errors['rxgroup'] ? true : false}
              helperText={errors['rxgroup']}
              key={'RxGroup'}
              fullWidth
              name={'rxgroup'}
              value={values.patient.medicare['rxgroup'] || ''}
              label={'RxGroup#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'rxgroup');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'Rx Member ID'}>
            <TextField
              error={errors['rxmemberid'] ? true : false}
              helperText={errors['rxmemberid']}
              key={'Rx Member ID'}
              fullWidth
              name={'rxmemberid'}
              value={values.patient.medicare['rxmemberid'] || ''}
              label={'Rx Member ID'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'rxmemberid');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'Medicare Part A ID'}>
            <TextField
              error={errors['partAid'] ? true : false}
              helperText={errors['partAid']}
              key={'Medicare Part A ID'}
              fullWidth
              name={'partAid'}
              value={values.patient.medicare['partAid'] || ''}
              label={'Medicare Part A ID#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'partAid');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'MBI'}>
            <TextField
              error={errors['mbi'] ? true : false}
              helperText={errors['mbi']}
              key={'MBI'}
              fullWidth
              name={'mbi'}
              value={values.patient.medicare['mbi'] || ''}
              label={'MBI#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'mbi');
              }}
              variant="outlined"
            />
          </Grid>
        </>
      )}
      <QualificationDialog
        open={openCostDialog}
        handleClose={handleClose}
        qulificationGroup={qualificationDialogText.current}
        isEligible={false}
      />
    </>
  );
}

export default MedicareFields;
