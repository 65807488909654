/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, DocumentData } from 'firebase/firestore';
import { useState, useEffect, useCallback, useMemo, ChangeEvent } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import theme from '../../theme';

function MedicationList() {
  const [medications, loadingMedications] = useCollection(
    collection(db, 'medications'),
    { snapshotListenOptions: { includeMetadataChanges: false } }
  );
  const [insuranceData, loadingInsuranceData] = useCollection(
    collection(db, 'insuranceCompanies'),
    { snapshotListenOptions: { includeMetadataChanges: false } }
  );
  const [diagnosisData, loadingDiagnosisList] = useCollection(
    collection(db, 'diagnosis'),
    { snapshotListenOptions: { includeMetadataChanges: false } }
  );
  const [source, setSource] = useState<string>('delta');
  const [medicationsList, setMedicationsList] = useState<DocumentData[] | null>(
    null
  );

  const [insuranceDataList, setInsuranceDataList] = useState<
    DocumentData[] | null
  >(null);
  const [insuranceProductList, setInsuranceProductList] = useState<
    DocumentData[] | null
  >(null);
  const [insurancePlanList, setInsurancePlanList] = useState<
    DocumentData[] | null
  >(null);
  const [insuranceValues, setInsuranceValues] = useState({
    company: '',
    product: '',
    plan: '',
  });
  const [filteredMedicationsList, setFilteredMedicationsList] = useState<
    DocumentData[] | null
  >(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [disease, setDisease] = useState<string>('');
  // Memoize sorted medications list to avoid re-sorting on every render
  const sortedMedicationsList = useMemo(() => {
    if (loadingMedications || !medications) return null;
    return medications.docs
      .map((doc) => doc.data())
      .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
  }, [loadingMedications, medications]);

  // Memoize sorted insurance data list
  const sortedInsuranceDataList = useMemo(() => {
    if (loadingInsuranceData || !insuranceData) return null;
    const list = insuranceData.docs
      .map((doc) => doc.data())
      .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    list.unshift({ name: 'Medicare', products: [] });
    return list;
  }, [loadingInsuranceData, insuranceData]);

  const sortedDiseaseList = useMemo(() => {
    if (loadingDiagnosisList || !diagnosisData) return null;
    const diseaseList = diagnosisData.docs
      .map((doc) => doc.data())
      .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    diseaseList.unshift({ name: 'All' });
    return diseaseList;
  }, [diagnosisData, loadingDiagnosisList]);

  // Set medications list and insurance data list only when they change
  useEffect(() => {
    setMedicationsList(sortedMedicationsList);
  }, [sortedMedicationsList]);

  useEffect(() => {
    setInsuranceDataList(sortedInsuranceDataList);
  }, [sortedInsuranceDataList]);
  // Filter medications list based on the search query
  useEffect(() => {
    if (!searchQuery || searchQuery === '') {
      if (
        insuranceValues.company === "I can't find my insurance company" &&
        medicationsList
      ) {
        const filteredList = medicationsList?.filter(
          (medication) => !medication.brand
        );
        setFilteredMedicationsList(filteredList);
        return;
      }
      setFilteredMedicationsList(medicationsList);
    } else {
      console.log('medicationsList');
      const filteredList = medicationsList?.filter(
        (medication) =>
          medication.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          medication.genericName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
      setFilteredMedicationsList(filteredList || null);
    }
  }, [searchQuery, medicationsList, insuranceValues.company]);
  // Filter medications list based on the search query
  useEffect(() => {
    if (!disease || disease === 'All') {
      setFilteredMedicationsList(medicationsList);
    } else {
      console.log(disease);
      const filteredList = medicationsList?.filter((medication) => {
        if (medication.diagnosis.includes(disease)) {
          return medication;
        }
      });
      console.log('filteredList', filteredList);
      setFilteredMedicationsList(filteredList || null);
    }
  }, [disease, medicationsList, insuranceValues.company]);
  // Update insurance products and plans based on selected values
  useEffect(() => {
    const insuranceProducts = insuranceDataList?.find(
      (item) => item.name === insuranceValues.company
    );
    if (
      insuranceValues.company ===
        "I don't have insurance coverage at this time" ||
      insuranceValues.company === "I can't find my insurance company" ||
      insuranceValues.company === 'Medicare' ||
      source === 'pinnacle'
    ) {
      setInsuranceProductList(null);
      setInsurancePlanList(null);
      return;
    }
    if (insuranceProducts) {
      const sortedProducts = insuranceProducts.products.sort((a: any, b: any) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );
      setInsuranceProductList(sortedProducts);
    } else {
      setInsuranceProductList(null);
    }

    const insurancePlans = insuranceProductList?.find(
      (item) => item.name === insuranceValues.product
    );
    if (insurancePlans) {
      const sortedPlans = insurancePlans.plans.sort((a: any, b: any) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );
      setInsurancePlanList(sortedPlans);
    } else {
      setInsurancePlanList(null);
    }
  }, [insuranceValues, insuranceDataList, insuranceProductList, source]);

  const handleInsuranceChange = useCallback((value: string | null) => {
    setInsuranceValues({ company: value || '', product: '', plan: '' });
    setInsuranceProductList(null);
    setInsurancePlanList(null);
  }, []);

  const handleProductChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInsuranceValues((prev) => ({
        ...prev,
        product: e.target.value,
        plan: '',
      }));
      setInsurancePlanList(null);
    },
    []
  );

  const handlePlanChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInsuranceValues((prev) => ({ ...prev, plan: e.target.value }));
  }, []);

  const handleMessage = (event: MessageEvent) => {
    const { data } = event;
    if (data && data.source) {
      setSource(data.source);
    }
  };
  window.addEventListener('message', handleMessage);

  const filterMedicationsOptions = (options: any[], state: any) => {
    const inputValue = state.inputValue;
    return options.filter((option) => {
      if (
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.genericName.toLowerCase().includes(inputValue.toLowerCase())
      ) {
        return option;
      }
      return false;
    });
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
  });
  const [listHeight, setListHeight] = useState(0);

  useEffect(() => {
    if (listHeight < 20000) {
      const stackElement = document.getElementById(`medlist`);
      if (stackElement) {
        setListHeight(
          120 + Number(stackElement.getBoundingClientRect().height)
        );
      }
    }
  }, [
    loadingMedications,
    loadingInsuranceData,
    medicationsList,
    insuranceDataList,
    listHeight,
  ]);
  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'resize',
        value: listHeight,
      },
      '*'
    );
  }, [listHeight]);

  return (
    <>
      {medicationsList &&
      medicationsList.length > 100 &&
      insuranceDataList &&
      insuranceDataList.length > 0 ? (
        <Stack
          id={'medlist'}
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          sx={{
            paddingTop: {
              sm: 2,
              md: 8,
            },
          }}>
          <Typography
            variant="h1"
            textAlign={'center'}
            fontSize={{
              sm: '3rem',
              md: '4.8rem',
            }}
            color={'#153b61'}>
            Medication List
          </Typography>
          <Stack
            spacing={2}
            alignItems={'stretch'}
            width={'100%'}
            className="container"
            sx={{
              overflowX: 'clip',
            }}>
            <Stack width={'100%'} alignItems={'center'}>
              <Stack
                spacing={2}
                bgcolor={theme.palette.primary.main}
                maxWidth={{ xs: '100%', sm: '60%' }}
                p={2}
                borderRadius={2}>
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color={'white'}
                  textAlign={'center'}>
                  If the medication you need isn't on our formulary, You can
                  check for generic equivalents within our list. Feel free to
                  request us to add new medication in the appropriate form.
                </Typography>

                <Button
                  sx={{
                    alignSelf: 'center',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: theme.palette.primary.main,
                      textAlign: 'center',
                    },
                  }}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  href="https://cd-clients.s3.eu-north-1.amazonaws.com/tprx/Medication+Formulary.pdf">
                  Download Medication List PDF
                </Button>
              </Stack>
            </Stack>
            {source === 'chc' || source === 'website' ? (
              <Grid container sx={{ width: '100%' }} spacing={2}>
                <Grid item xs={12} sm={3} key={'insurance company grid'}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    disablePortal
                    value={insuranceValues['company'] || null}
                    options={
                      insuranceDataList?.map((option) => option.name) || []
                    }
                    onChange={(_, value) =>
                      handleInsuranceChange(value as string | null)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        name={`company`}
                        value={insuranceValues['company'] || ''}
                        label={'Insurance Company'}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} key={'insurance product grid'}>
                  <TextField
                    key={'insurance product'}
                    fullWidth
                    select
                    required={
                      insuranceValues['company'] ===
                      `I don't have insurance coverage at this time`
                        ? false
                        : true
                    }
                    name={'product'}
                    value={insuranceValues['product'] || ''}
                    label={'Insurance Product'}
                    onChange={handleProductChange}
                    variant="outlined">
                    {insuranceProductList ? (
                      insuranceProductList?.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={''}>No Products Available</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3} key={'insurance plan grid'}>
                  <TextField
                    key={'insurance plan'}
                    fullWidth
                    select
                    required={
                      (insurancePlanList && insurancePlanList.length === 1) ||
                      insuranceValues['company'] ===
                        `I don't have insurance coverage at this time`
                        ? false
                        : true
                    }
                    name={'plan'}
                    value={insuranceValues['plan'] || ''}
                    label={'Insurance Plan'}
                    onChange={handlePlanChange}
                    variant="outlined">
                    {insurancePlanList ? (
                      insurancePlanList?.map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name ? item.name : ''}>
                          {item.name ? item.name : 'All'}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={''}>No Plans Available</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3} key={'diagnosis grid'}>
                  <TextField
                    key={'diagnosis'}
                    fullWidth
                    select
                    name={'product'}
                    value={disease || ''}
                    label={'Filter by disease'}
                    onChange={(e) => setDisease(e.target.value)}
                    variant="outlined">
                    {sortedDiseaseList &&
                      sortedDiseaseList.map((diagnosis) => (
                        <MenuItem key={diagnosis.name} value={diagnosis.name}>
                          {diagnosis.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} key={'search medications grid'}>
                  <Autocomplete
                    filterOptions={filterMedicationsOptions}
                    disablePortal
                    options={medicationsList}
                    value={
                      medicationsList.find(
                        (option) => option.name === searchQuery
                      ) || null
                    }
                    getOptionLabel={(option: any) =>
                      `${option.name} (${option.genericName})`
                    }
                    onChange={(_e, value: any) => {
                      setSearchQuery(!value ? '' : value.name);
                    }}
                    renderOption={(props, option: any) => {
                      const { key, ...optionProps } = props;
                      return (
                        <Stack
                          key={key}
                          component="li"
                          {...optionProps}
                          direction={'row'}>
                          <Stack direction={'column'} mr={'auto'}>
                            <Typography variant="body1">
                              {option.name}
                            </Typography>
                            <Typography variant="body2">
                              ({option.genericName})
                            </Typography>
                          </Stack>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: '1.1rem',
                              fontWeight: 600,
                              backgroundColor: option.brand
                                ? theme.palette.primary.dark
                                : theme.palette.primary.main,
                              color: theme.palette.common.white,
                              padding: '0.2rem 0.5rem',
                              borderRadius: '0.5rem',
                              marginLeft: '2rem',
                            }}>
                            {option.brand ? 'Brand' : 'Generic'}
                          </Typography>
                        </Stack>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        value={searchQuery || null}
                        name={'medicationName'}
                        label={'Select Medication'}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container sx={{ width: '100%' }} spacing={2}>
                <Grid item xs={12} sm={6} key={'diagnosis grid'}>
                  <TextField
                    key={'diagnosis'}
                    fullWidth
                    select
                    SelectProps={{
                      MenuProps: {
                        style: {
                          maxHeight: 500,
                        },
                      },
                    }}
                    name={'product'}
                    value={disease || ''}
                    label={'Filter by disease'}
                    onChange={(e) => setDisease(e.target.value)}
                    variant="outlined">
                    {sortedDiseaseList &&
                      sortedDiseaseList.map((diagnosis) => (
                        <MenuItem key={diagnosis.name} value={diagnosis.name}>
                          {diagnosis.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} key={'search medications grid'}>
                  <Autocomplete
                    filterOptions={filterMedicationsOptions}
                    disablePortal
                    options={medicationsList}
                    value={
                      medicationsList.find(
                        (option) => option.name === searchQuery
                      ) || null
                    }
                    getOptionLabel={(option: any) =>
                      `${option.name} (${option.genericName})`
                    }
                    onChange={(_e, value: any) => {
                      setSearchQuery(!value ? '' : value.name);
                    }}
                    renderOption={(props, option: any) => {
                      const { key, ...optionProps } = props;
                      return (
                        <Stack
                          key={key}
                          component="li"
                          {...optionProps}
                          direction={'row'}>
                          <Stack direction={'column'} mr={'auto'}>
                            <Typography variant="body1">
                              {option.name}
                            </Typography>
                            <Typography variant="body2">
                              ({option.genericName})
                            </Typography>
                          </Stack>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: '1.1rem',
                              fontWeight: 600,
                              backgroundColor: option.brand
                                ? theme.palette.primary.dark
                                : theme.palette.primary.main,
                              color: theme.palette.common.white,
                              padding: '0.2rem 0.5rem',
                              borderRadius: '0.5rem',
                              marginLeft: '2rem',
                            }}>
                            {option.brand ? 'Brand' : 'Generic'}
                          </Typography>
                        </Stack>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        value={searchQuery || null}
                        name={'medicationName'}
                        label={'Select Medication'}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {insuranceValues.plan !== '' ||
            insuranceValues.product === 'All' ||
            insuranceValues.company === 'Medicare' ||
            insuranceValues.company ===
              "I don't have insurance coverage at this time" ||
            insuranceValues.company === "I can't find my insurance company" ||
            source === 'pinnacle' ||
            source === 'delta' ||
            source === 'sparks' ? (
              <Box
                sx={{
                  overflowX: 'auto',
                  xs: { width: '100svw' },
                }}>
                <Grid
                  container
                  borderBottom={1}
                  pb={1}
                  wrap="nowrap"
                  width={{ xs: '66rem', sm: 'auto' }}>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      xs: {
                        flex: 1,
                        maxWidth: 'unset',
                        width: '20rem',
                        marginRight: '2rem',
                      },
                    }}>
                    <Typography variant="h3" color={'#153b61'} noWrap>
                      Medication Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      xs: {
                        flex: 1,
                        maxWidth: 'unset',
                        width: '20rem',
                        marginRight: '2rem',
                      },
                    }}>
                    <Typography variant="h3" color={'#153b61'}>
                      Strength
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      xs: {
                        flex: 1,
                        maxWidth: 'unset',
                        width: '20rem',
                        marginRight: '2rem',
                      },
                    }}>
                    <Typography variant="h3" color={'#153b61'}>
                      Price
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      xs: {
                        flex: 1,
                        maxWidth: 'unset',
                        width: '20rem',
                        marginRight: '2rem',
                      },
                    }}>
                    <Typography variant="h3" color={'#153b61'}>
                      Disease
                    </Typography>
                  </Grid>
                </Grid>
                {filteredMedicationsList?.map((medication, index) => (
                  <Grid
                    key={index}
                    container
                    borderBottom={1}
                    pb={1}
                    wrap="nowrap"
                    width={{ xs: '66rem', sm: '100%' }}>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        xs: {
                          flex: 1,
                          maxWidth: 'unset',
                          width: '20rem',
                          marginRight: '2rem',
                        },
                        paddingRight: '2rem',
                      }}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}>
                        <Stack>
                          <Typography
                            variant="body1"
                            fontWeight={600}
                            color={'#153b61'}>
                            {medication.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              marginTop: 0,
                            }}>
                            {medication.genericName}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '1.1rem',
                            fontWeight: 600,
                            backgroundColor: medication.brand
                              ? theme.palette.primary.dark
                              : theme.palette.primary.main,
                            color: theme.palette.common.white,
                            padding: '0.2rem 0.5rem',
                            borderRadius: '0.5rem',
                            marginLeft: '2rem',
                          }}>
                          {medication.brand ? 'Brand' : 'Generic'}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        xs: {
                          flex: 1,
                          maxWidth: 'unset',
                          width: '20rem',
                          marginRight: '2rem',
                        },
                      }}>
                      {medication.options.map((option: any, index: number) => (
                        <Grid key={index}>
                          <Typography variant="body2">
                            {option.strength}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        xs: {
                          flex: 1,
                          maxWidth: 'unset',
                          width: '20rem',
                          marginRight: '2rem',
                        },
                      }}>
                      {medication.options.map((option: any, index: number) => (
                        <Grid key={index}>
                          <Typography variant="body2">
                            {option.price === 'No cost'
                              ? `${option.price}, refilled every 90 days`
                              : `${option.price}`}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        xs: {
                          flex: 1,
                          maxWidth: 'unset',
                          width: '20rem',
                          marginRight: '2rem',
                        },
                      }}>
                      <Grid key={index}>
                        <Typography variant="body2">
                          {medication.diagnosis}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            ) : (
              <Stack alignItems={'center'}>
                <Typography variant="h3" fontWeight={600}>
                  Please choose an insurance product to view the available
                  medications.
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      ) : (
        <div className="loader-s">
          <div className="loader" />
        </div>
      )}
    </>
  );
}
export default MedicationList;
