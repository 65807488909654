import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { Container, Stack, Typography, Link } from '@mui/material';
import Grid from '@mui/material/Grid'; // Correct Grid import
import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
function SupportHub() {
  const generalFAQ = [
    {
      question: 'What is TransparentPriceRx Pharmacy Program (TPRX)?',
      answer:
        "TransparentPriceRx is neither an insurance product nor a pharmacy discount card. We are a stand alone pharmacy benefit that specializes in the top 80 brand and +3000 most commonly used generic medications nationwide. We provide transparency on drug costs, working with you, your healthcare provider, and pharmaceutical manufacturers' pharmacies.",
    },
    {
      question: 'How does the TPRX program work?',
      answer: (
        <ul>
          <li>
            Your agent will check your eligibility requirements for the
            medication you are looking for.
          </li>
          <li>Your agent will explain what to expect from the program.</li>
          <li>Your agent will fill out the membership form.</li>
          <li>
            TPRX will guide you and your healthcare provider with the
            instructions to follow.
          </li>
          <li>
            The provider eFaxes or calls in the order directly to the
            manufacturer that has your medication.
          </li>
          <li>Medications are delivered to your door within 2 to 3 weeks.</li>
        </ul>
      ),
    },
    {
      question: 'How are your prices so cheap?',
      answer:
        'Our prices are so affordable because we connect you directly with the manufacturer, cutting out the middlemen.',
    },
    {
      question: 'Can I get all my medications here?',
      answer: (
        <p>
          No, we are limited to a set list of medications. You can check the
          current medications available on our website at{' '}
          <a
            className="faq-link"
            href="https://www.transparentpricerx.com/medications">
            transparentpricerx.com/medications
          </a>
          .
        </p>
      ),
    },
    {
      question: 'How is TPRX different from other online pharmacies?',
      answer:
        'TPRX is an exclusive program that has a fixed formulary and pricing, covering the top 80 brand medications and +3000 generic medications used nationally.',
    },
    {
      question:
        'Do you have a list of healthcare providers who are familiar with or recommend TPRX?',
      answer:
        'Any healthcare provider can participate as we follow HIPAA and pharmacy guidelines.',
    },
    {
      question:
        'Is there an expiration date for the medications I receive from TPRX?',
      answer:
        'The medications are shipped directly from major US suppliers only. You will receive the medications directly from the manufacturer for one year delivered in 90 days supply.',
    },
    {
      question:
        "Why can't I just buy medications directly from the manufacturer?",
      answer:
        'TPRX leverages long-term relationships in the healthcare industry to provide these services. Guiding you and your healthcare provider through the complex regulations of these programs, and help you in case of denials with alternatives.',
    },
    {
      question: "Why can't kids under 21 use TPRX?",
      answer:
        'You must be 21 years of age or older to create an account and order prescriptions. We can only fill prescriptions for people 21 years of age or older.',
    },
    {
      question:
        'Is TPRX an Insurance Product or a pharmacy discount card program?',
      answer:
        'No, TPRX is neither an insurance product nor a pharmacy discount card program. It operates as a stand-alone pharmacy program.',
    },
    {
      question:
        'What is the process for obtaining my medication through TransparentPriceRx?',
      answer: (
        <p>
          To obtain your medication, follow the instructions sent in the email
          titled "Next Steps - TransparentPriceRx." Alternatively, visit the
          Client Portal{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and check the section "Steps for obtaining & tracking your
          medication".
        </p>
      ),
    },
    {
      question:
        'What is the difference between brand and generic medication processes in TransparentPriceRx?',
      answer: (
        <p>
          Detailed instructions are available in the Client Portal{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          check the section "Steps for obtaining & tracking your medication”
        </p>
      ),
    },
    {
      question: 'What documents do I need to provide to get my medication?',
      answer: (
        <p>
          For brand medications, provide your healthcare provider with all the
          paperwork specified in the email "Next Steps - TransparentPriceRx."
          You can also refer to the Client Portal{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and check the section "Steps for obtaining & tracking your
          medication".
        </p>
      ),
    },
    {
      question: 'How long does it take to receive my medication once I apply?',
      answer:
        'Once your healthcare provider faxes the application directly to the manufacturer, it typically takes 2 to 3 weeks for delivery.',
    },
    {
      question: 'Can I track the status of my medication order?',
      answer: (
        <p>
          Yes, to track the status of your medication order, visit the Client
          Portal{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and check the section "Steps for obtaining & tracking your medication”
          section for updates.
        </p>
      ),
    },
    {
      question: 'What should I do if I need help with my application?',
      answer: (
        <p>
          If you need help with your application, this chat is available live
          from 9 am to 3 pm EST. Alternatively, you can email us at{' '}
          <a
            className="faq-link"
            href="maito:customerservice@transparentpricerx.com">
            customerservice@transparentpricerx.com
          </a>{' '}
          for assistance
        </p>
      ),
    },
    {
      question:
        'How do I contact customer service if I have questions or issues?',
      answer: (
        <p>
          To contact customer service if you have questions or issues, this chat
          is available live from 9 am to 3 pm EST. You can also email us at{' '}
          <a
            className="faq-link"
            href="maito:customerservice@transparentpricerx.com">
            customerservice@transparentpricerx.com
          </a>{' '}
          for any queries or issues.
        </p>
      ),
    },
    {
      question:
        'Is there a cost associated with getting my medication through TransparentPriceRx?',
      answer:
        'Yes, you will pay the price listed on our medication list on the website (If the price is NoCost, you will not pay anything else). There are no hidden fees; the price is transparent.',
    },
  ];
  const brandFAQ = [
    {
      question:
        'What steps do I need to follow to get my brand-name medication at no cost?',
      answer: (
        <p>
          To get your brand-name medication at no cost, follow the instructions
          sent in the email titled "Next Steps - TransparentPriceRx."
          Alternatively, visit the Client Portal{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and check the section "Steps for obtaining & tracking your medication”
        </p>
      ),
    },
    {
      question:
        'Why do I need to print and sign the application with wet ink for brand medications?',
      answer:
        'You need to print and sign the application with wet ink due to manufacturer’s and government regulations.',
    },
    {
      question:
        'What income verification documents are acceptable for brand medications?',
      answer: (
        <p>
          Provide the healthcare provider with the most accurate income
          verification document for your current situation, (choose one that
          shows your current financial situation), such as:
          <ul>
            <li>Last 2 months' pay stubs</li>
            <li>Last 2 months' Social Security checks</li>
            <li>Last 2 months' unemployment checks</li>
          </ul>
        </p>
      ),
    },
    {
      question:
        'Why Do I Need to Provide Income Verification for the Patient Assistance Program (PAP)?',
      answer: `For the Patient Assistance Program (PAP), it is mandatory that your income documentation is sent directly from your healthcare provider's office to the manufacturer, along with the wet ink-signed application. This ensures compliance with program requirements, confirms eligibility, and maintains the integrity of the process. Your information is handled securely to help provide medications to those who qualify.`,
    },
    {
      question:
        'What should I do if I am unemployed and need to provide proof of income?',
      answer:
        "If you are unemployed, provide the last 2 months' unemployment checks as proof of income.",
    },
    {
      question:
        'How do I submit my application and income verification documents to my healthcare provider?',
      answer:
        'To submit your application and income verification documents to your healthcare provider, print and attach the income verification documents to the signed application.',
    },
    {
      question:
        'Who is responsible for faxing the completed application to the manufacturer?',
      answer:
        'Your healthcare provider is responsible for faxing the completed application to the manufacturer.',
    },
    {
      question:
        'How long does it take for brand medications to be delivered once the application is faxed?',
      answer:
        'Brand medications take 2 to 3 weeks to be delivered once the application is faxed.',
    },
    {
      question:
        'What should I do if I need to add a new brand medication to my request?',
      answer: (
        <p>
          To add a new brand medication to your request, visit the Client Portal
          at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
        </p>
      ),
    },
    {
      question: 'How are automatic refills managed for brand medications?',
      answer:
        'Automatic refills for brand medications are managed from the beginning of the application process working with your healthcare provider, by ensuring you receive your medication every 90 days directly from the manufacturer for 1 year.',
    },
    {
      question:
        'Can I check the delivery status of my brand medication directly with TransparentPriceRx?',
      answer: (
        <p>
          Yes, to check the delivery status of your brand medication, visit the
          Client’s Portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and check the "Process for Order and Tracking Medication" section for
          updates.
        </p>
      ),
    },
  ];

  const genericFAQ = [
    {
      question:
        'What is the process for obtaining generic medication through TransparentPriceRx?',
      answer: (
        <p>
          To obtain generic medication through TransparentPriceRx, follow the
          instructions sent in the email titled "Next Steps -
          TransparentPriceRx." Alternatively, visit the Client Portal{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and check the section "Steps for obtaining & tracking your medication”
        </p>
      ),
    },
    {
      question: 'How do I enroll with RX Outreach for my generic medication?',
      answer: (
        <p>
          To enroll with RX Outreach, please go to{' '}
          <a className="faq-link" href="https://portal.rxoutreach.org">
            portal.rxoutreach.org
          </a>{' '}
          and check the section "Steps for obtaining & tracking your medication”
        </p>
      ),
    },
    {
      question:
        'Do I need to contact my doctor to send my prescription to RX Outreach?',
      answer:
        'TransparentpriceRX has already sent the application order to your healthcare provider via efax.  After 3 days, send a gentle reminder to your healthcare provider to ensure they have faxed the medication request to RX Outreach.',
    },
    {
      question:
        'How is the payment for generic medication handled through RX Outreach?',
      answer: 'To keep prices transparent, you will pay RX Outreach directly.',
    },
    {
      question:
        'How long does it take for generic medications to be delivered after ordering?',
      answer:
        'It takes between 1 to 2 weeks for generic medications to be delivered after ordering.',
    },
    {
      question:
        'What should I do if I need to order additional generic medications?',
      answer:
        'To order additional generic medications, you and your healthcare provider are already in the system. You can request your healthcare provider to order them directly.',
    },
    {
      question:
        'What happens if my healthcare provider does not send the prescription to RX Outreach?',
      answer:
        'You will not receive your medication if your healthcare provider does not send the prescription to RX Outreach. Ensure they have faxed the medication request to RX Outreach.',
    },
  ];

  const updateFAQ = [
    {
      question: 'How can I update my healthcare provider information?',
      answer: (
        <p>
          To update your healthcare provider information, visit the Client’s
          Portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and update the information in the "Update Healthcare Provider’s
          Information" section.
        </p>
      ),
    },
    {
      question:
        'What should I do if I need to change the information after submitting an application?',
      answer: (
        <p>
          If you need to change the information after submitting an application,
          visit the Client’s Portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and update your personal or provider information.
        </p>
      ),
    },
  ];

  const medicationFAQ = [
    {
      question:
        'What steps should I take if my medication has not arrived within the expected time frame?',
      answer: (
        <p>
          If your medication has not arrived within the expected time frame,
          contact your healthcare provider to confirm they have faxed the
          medication request to the manufacturer.{' '}
          <b>
            Ensure the provider follows up with the manufacturer directly with
            the manufacture with the direct healthcare provider line each
            manufacture has that TPRX has given in the original and follow up
            fax processes.
          </b>
        </p>
      ),
    },
    {
      question: 'How can I check the status of my medication delivery?',
      answer: (
        <p>
          To check the status of your medication delivery, visit the Client’s
          Portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and check the "Process for Order and Tracking Medication" section.
        </p>
      ),
    },
    {
      question:
        'What actions should I take if my healthcare provider has not received the fax instructions?',
      answer: (
        <p>
          If your healthcare provider has not received the fax instructions,
          confirm the fax number with your provider. Update your Healthcare Fax
          in the client portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and Contact us at customerservice@transparentpricerx.com to resend the
          necessary information.
        </p>
      ),
    },
    {
      question:
        'Who should I contact if there are errors in the shipment of my medication?',
      answer:
        'If there are errors in the shipment of your medication, report any errors to your healthcare provider, who will coordinate with the manufacturer to resolve the issue.',
    },
  ];

  const applicationFAQ = [
    {
      question: 'What do I do if there is a problem with my application file?',
      answer: (
        <p>
          If there is a problem with your application file, visit the Client’s
          Portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and update your personal or provider information.
        </p>
      ),
    },
    {
      question: 'How do I handle a denied medication request?',
      answer: (
        <p>
          To handle a denied medication request, obtain a denial letter from the
          manufacturer. Contact us at{' '}
          <a
            className="faq-link"
            href="mailto:customerservice@transparentpricerx.com">
            customerservice@transparentpricerx.com
          </a>{' '}
          for further steps, which may include reconsideration or alternative
          options.
        </p>
      ),
    },
    {
      question:
        'What actions should I take if I believe my medication request was incorrectly denied?',
      answer: (
        <p>
          If you believe your medication request was incorrectly denied, request
          a denial letter from the manufacturer, and contact us at{' '}
          <a
            className="faq-link"
            href="mailto:customerservice@transparentpricerx.com">
            customerservice@transparentpricerx.com
          </a>{' '}
          .We will assist in reviewing and appealing the decision.
        </p>
      ),
    },
    {
      question:
        "What should I do if I didn't qualify for medication due to income limits?",
      answer:
        "If you didn't qualify for medication due to income limits, request a denial letter from the manufacturer. Provide accurate income information, recent updates, or pay stubs. We will create a reconsideration letter for your healthcare provider to send directly to the manufacturer.",
    },
    {
      question:
        "What should I do if I didn't qualify for medication due to Private Insurance?",
      answer:
        "If you didn't qualify for medication due to Private Insurance, get insurance details and proof of no coverage from your agent. We will create a reconsideration letter for your healthcare provider to send directly to the manufacturer.",
    },
  ];
  const eligibilityFAQ = [
    {
      question: 'What are the eligibility requirements for your program?',
      answer: (
        <p>
          Eligibility typically depends on income level and lack of prescription
          coverage. Visit the Client’s Portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          to check eligibility. Create a new medication order, and the system
          will determine your eligibility based on the household size and income
          provided.
        </p>
      ),
    },
    {
      question:
        'Can I add more medications to my order after my initial application?',
      answer: (
        <p>
          Yes, to add more medications to your order after your initial
          application, visit the Client’s Portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and use the "Order New Medication" section.
        </p>
      ),
    },
    {
      question: 'How do I know what medications are covered by your program?',
      answer: (
        <p>
          To know what medications are covered by our program, check the
          medication list on our website at{' '}
          <a className="faq-link" href="www.transparentpricerx.com/medications">
            www.transparentpricerx.com/medications
          </a>{' '}
          You can check by medication, by disease, by brand, or by generic.
        </p>
      ),
    },
    {
      question:
        'Can I request a different medication if the one I initially applied for is not available?',
      answer: (
        <p>
          Yes, you can request a different medication if the one you initially
          applied for is not available. Visit the Client’s Portal at{' '}
          <a className="faq-link" href="app.transparentpricerx.com">
            app.transparentpricerx.com
          </a>{' '}
          and use the "Order New Medication" section.
        </p>
      ),
    },
  ];

  const communicationFAQ = [
    {
      question: 'Why Does TransparentpriceRX not have a Call Center',
      answer:
        "At TransparentpriceRX, we prioritize the privacy and security of our clients' sensitive health information. Due to HIPAA regulations related to pharmacy rules and the need to handle sensitive health information appropriately, we have opted not to operate a call center. Instead, all communication with healthcare providers is directed via fax to ensure compliance with these stringent regulations. To provide immediate assistance to our customers, we offer a live chat feature. This allows customers to ask any questions and receive prompt responses, ensuring their needs are taken care of efficiently and securely.",
    },
    {
      question: 'How can I contact customer service for assistance?',
      answer: (
        <p>
          To contact customer service for assistance, this chat is live from 9
          am to 3 pm EST. You can also email us at{' '}
          <a
            className="faq-link"
            href="mailto:customerservice@transparentpricerx.com">
            customerservice@transparentpricerx.com
          </a>{' '}
          or use the contact form on our website.
        </p>
      ),
    },
  ];

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (id: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      // if expanded, set id to open/expand, close it otherwise
      setExpanded(isExpanded ? id : false);
    };
  return (
    <>
      <Container>
        <Grid
          container
          pt={'6.4rem'}
          gap={4}
          pb={'6.4rem'}
          columns={{ md: 12, xs: 1 }}
          direction={{ md: 'row', xs: 'column-reverse' }}>
          <Grid xs={1} md={8}>
            <Stack>
              <Typography variant="h2" id="generalFAQ" pt={8} pb={4}>
                General Program Information
              </Typography>
              <Stack spacing={2}>
                {generalFAQ.map((item, index) => (
                  <Accordion
                    key={`general-${index}`} // Unique key for React list
                    onChange={handleChange(`general-${index}`)}
                    expanded={expanded === `general-${index}`}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `general-${index}` ? (
                          <RemoveCircle color="primary" />
                        ) : (
                          <AddCircle color="primary" />
                        )
                      }>
                      <Typography variant="h3">{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
              <Typography variant="h2" id="brandFAQ" pt={8} pb={4}>
                Brand Medications FAQ
              </Typography>
              <Stack spacing={2}>
                {brandFAQ.map((item, index) => (
                  <Accordion
                    key={`brand-${index}`}
                    onChange={handleChange(`brand-${index}`)}
                    expanded={expanded === `brand-${index}`}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `brand-${index}` ? (
                          <RemoveCircle color="primary" />
                        ) : (
                          <AddCircle color="primary" />
                        )
                      }>
                      <Typography variant="h3">{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
              <Typography variant="h2" id="genericFAQ" pt={8} pb={4}>
                Generic Medications FAQ
              </Typography>
              <Stack spacing={2}>
                {genericFAQ.map((item, index) => (
                  <Accordion
                    key={`generic-${index}`}
                    onChange={handleChange(`generic-${index}`)}
                    expanded={expanded === `generic-${index}`}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `generic-${index}` ? (
                          <RemoveCircle color="primary" />
                        ) : (
                          <AddCircle color="primary" />
                        )
                      }>
                      <Typography variant="h3">{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
              <Typography variant="h2" id="updateFAQ" pt={8} pb={4}>
                Update Information
              </Typography>
              <Stack spacing={2}>
                {updateFAQ.map((item, index) => (
                  <Accordion
                    key={`update-${index}`}
                    onChange={handleChange(`update-${index}`)}
                    expanded={expanded === `update-${index}`}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `update-${index}` ? (
                          <RemoveCircle color="primary" />
                        ) : (
                          <AddCircle color="primary" />
                        )
                      }>
                      <Typography variant="h3">{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
              <Typography variant="h2" id="medicationFAQ" pt={8} pb={4}>
                Medication Delivery and Issues
              </Typography>
              <Stack spacing={2}>
                {medicationFAQ.map((item, index) => (
                  <Accordion
                    key={`medication-${index}`}
                    onChange={handleChange(`medication-${index}`)}
                    expanded={expanded === `medication-${index}`}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `medication-${index}` ? (
                          <RemoveCircle color="primary" />
                        ) : (
                          <AddCircle color="primary" />
                        )
                      }>
                      <Typography variant="h3">{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
              <Typography variant="h2" id="applicationFAQ" pt={8} pb={4}>
                Application Issues and Denials
              </Typography>
              <Stack spacing={2}>
                {applicationFAQ.map((item, index) => (
                  <Accordion
                    key={`application-${index}`}
                    onChange={handleChange(`application-${index}`)}
                    expanded={expanded === `application-${index}`}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `application-${index}` ? (
                          <RemoveCircle color="primary" />
                        ) : (
                          <AddCircle color="primary" />
                        )
                      }>
                      <Typography variant="h3">{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
              <Typography variant="h2" id="eligibilityFAQ" pt={8} pb={4}>
                Program Eligibility and Medications
              </Typography>
              <Stack spacing={2}>
                {eligibilityFAQ.map((item, index) => (
                  <Accordion
                    key={`eligibility-${index}`}
                    onChange={handleChange(`eligibility-${index}`)}
                    expanded={expanded === `eligibility-${index}`}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `eligibility-${index}` ? (
                          <RemoveCircle color="primary" />
                        ) : (
                          <AddCircle color="primary" />
                        )
                      }>
                      <Typography variant="h3">{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
              <Typography variant="h2" id="communicationFAQ" pt={8} pb={4}>
                Communication Information
              </Typography>
              <Stack spacing={2}>
                {communicationFAQ.map((item, index) => (
                  <Accordion
                    key={`communication-${index}`}
                    onChange={handleChange(`communication-${index}`)}
                    expanded={expanded === `communication-${index}`}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `communication-${index}` ? (
                          <RemoveCircle color="primary" />
                        ) : (
                          <AddCircle color="primary" />
                        )
                      }>
                      <Typography variant="h3">{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={1} md={3}>
            <Stack
              spacing={2}
              position={'sticky'}
              top={'6.4rem'}
              p={4}
              mt={8}
              sx={{
                backgroundColor: 'Background',
                borderRadius: '1.6rem',
              }}>
              <Typography variant="h3">Table of Content</Typography>
              <Link to="#generalFAQ" smooth component={HashLink}>
                <Typography variant="h4">General Program </Typography>
              </Link>
              <Link to="#brandFAQ" smooth component={HashLink}>
                <Typography variant="h4">Brand Medication </Typography>
              </Link>
              <Link to="#genericFAQ" smooth component={HashLink}>
                <Typography variant="h4">Generic Medication </Typography>
              </Link>
              <Link to="#updateFAQ" smooth component={HashLink}>
                <Typography variant="h4">Update Information</Typography>
              </Link>
              <Link to="#medicationFAQ" smooth component={HashLink}>
                <Typography variant="h4">
                  Medication Delivery and Issues
                </Typography>
              </Link>
              <Link to="#applicationFAQ" smooth component={HashLink}>
                <Typography variant="h4">
                  Application Issues and Denials
                </Typography>
              </Link>
              <Link to="#eligibilityFAQ" smooth component={HashLink}>
                <Typography variant="h4">
                  Program Eligibility and Medications
                </Typography>
              </Link>
              <Link to="#communicationFAQ" smooth component={HashLink}>
                <Typography variant="h4">Communication Information</Typography>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default SupportHub;
