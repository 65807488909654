/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import theme from '../../theme';
import { storage } from '../../firebase-setup/firebase';
import { ref, uploadBytes } from 'firebase/storage';

const CustomTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#f5f5f5',
    padding: '0',
  },
  '& .MuiInput-root::before': {
    content: 'none',
    borderBottom: 'unset',
  },
  '& .MuiInput-root.Mui-focused::before': {
    borderBottom: 'unset', // Change on focus
  },
  '& .MuiInput-root.Mui-focused::after': {
    borderBottom: 'unset', // Change on focus
  },
  '& .MuiInput-root.Mui-focused': {
    outline: '2px solid #121212', // Change on focus
    borderRadius: '4px',
  },
}));

const MemoizedMessageField = ({
  message,
  type,
  index,
  handleFieldChange,
}: any) => {
  if (!message) return null;

  return (
    <Stack spacing={2}>
      <CustomTextField
        fullWidth
        multiline
        variant="standard"
        label={type}
        value={message[type]}
        onChange={(e) => handleFieldChange(index, type, e.target.value)}
      />
    </Stack>
  );
};
function formatTimeToHHMM(value: string): string {
  // Remove any non-numeric characters
  const cleanedValue = value.replace(/[^0-9]/g, '');
  // Handle cases with fewer than 4 digits
  let limitedInput = cleanedValue.slice(0, 4);
  if (limitedInput.length <= 2) {
    const hours = parseInt(limitedInput, 10);
    if (hours > 23) {
      limitedInput = '23';
    }
  }
  if (limitedInput.length > 2) {
    const minutes = parseInt(limitedInput.slice(2), 10);
    if (minutes > 59) {
      limitedInput = `${limitedInput.slice(0, 2)}59`;
    }
  }
  // Add colon between hours and minutes
  const formattedValue = `${limitedInput.slice(0, 2)}:${limitedInput.slice(2)}`;
  return formattedValue;
}

function EditableMessages({ smsFiles, selectedFile }: any) {
  const [editedMessages, setEditedMessages] = useState<any[]>([]);
  const [renderedMessages, setRenderedMessages] = useState<any[]>([]);

  const selectedFileData = smsFiles.find(
    (file: any) => file.fileName === selectedFile
  );

  useEffect(() => {
    if (selectedFileData?.content?.messages) {
      const messages = [...selectedFileData.content.messages];
      const orderedMessages = messages.sort((a: any, b: any) => {
        if (a.sendAfterDays === b.sendAfterDays) {
          return a.sendAtTime.localeCompare(b.sendAtTime);
        }
        return a.sendAfterDays - b.sendAfterDays;
      });

      setEditedMessages(orderedMessages);
      setRenderedMessages(orderedMessages); // Use a separate state for rendering
    }
  }, [selectedFile, selectedFileData]);

  const handleFieldChange = useCallback(
    (index: number, field: string, value: string | number) => {
      if (field === 'sendAfterDays') {
        value = parseInt(value as string);
      }
      if (field === 'sendAtTime') {
        const newValue = formatTimeToHHMM(value as string);
        setEditedMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[index] = {
            ...updatedMessages[index],
            [field]: newValue,
          };
          return updatedMessages;
        });
        return;
      }
      setEditedMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[index] = { ...updatedMessages[index], [field]: value };
        return updatedMessages;
      });
    },
    []
  );
  const handleAddMessage = useCallback(() => {
    const newMessage = {
      text: '',
      sendAfterDays: 0,
      sendAtTime: '00:00',
    };

    setEditedMessages((prevMessages) => [...prevMessages, newMessage]);
    setRenderedMessages((prevMessages) => [...prevMessages, newMessage]); // Add to rendered messages
  }, []);
  const handleMessageDelete = useCallback((index: number) => {
    setEditedMessages((prevMessages) => {
      if (index < 0 || index >= prevMessages.length) {
        console.error('Invalid index for deletion:', index);
        return prevMessages; // Return the current state if index is invalid
      }
      const updatedMessages = [...prevMessages];
      updatedMessages.splice(index, 1); // Remove the message at the specified index

      return updatedMessages;
    });
    setRenderedMessages((prevMessages) => {
      if (index < 0 || index >= prevMessages.length) {
        console.error('Invalid index for deletion:', index);
        return prevMessages; // Return the current state if index is invalid
      }
      const updatedMessages = [...prevMessages];
      updatedMessages.splice(index, 1); // Remove the message at the specified index

      return updatedMessages;
    });
  }, []);

  const handleSaveChanges = useCallback(async () => {
    // Ensure the structure is correct
    const updatedFile = {
      messages: editedMessages,
    };

    const storageRef = ref(storage, `/smsFiles/${selectedFile}`);
    const updatedFileBlob = new Blob([JSON.stringify(updatedFile)], {
      type: 'application/json',
    });

    try {
      await uploadBytes(storageRef, updatedFileBlob);
      console.log('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }, [editedMessages, selectedFile]);

  return (
    <Grid container spacing={6}>
      <Grid item sm={9}>
        <Stack width={'100%'} spacing={4}>
          {renderedMessages.map((message, index) => (
            <Stack
              key={message.id || index}
              className="tprx-card"
              justifyContent={'center'}
              alignItems={'stretch'}>
              <Stack direction="row" spacing={2}>
                <Typography variant="h6">Message {index + 1}</Typography>
                <IconButton
                  size="small"
                  color="error"
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleMessageDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
              <MemoizedMessageField
                key={`${message.id || index}1`}
                message={editedMessages[index]}
                type="sendAfterDays"
                index={index}
                handleFieldChange={handleFieldChange}
              />
              <MemoizedMessageField
                key={`${message.id || index}2`}
                message={editedMessages[index]}
                type="sendAtTime"
                index={index}
                handleFieldChange={handleFieldChange}
              />
              <MemoizedMessageField
                key={`${message.id || index}3`}
                message={editedMessages[index]}
                type="text"
                index={index}
                handleFieldChange={handleFieldChange}
              />
            </Stack>
          ))}
        </Stack>
      </Grid>
      <Grid item sm={3}>
        <Stack
          sx={{
            position: 'sticky',
            top: 32,
          }}
          spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSaveChanges}>
            Save Changes
          </Button>
          <Button variant="outlined" size="small" onClick={handleAddMessage}>
            Add New Message
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default EditableMessages;
