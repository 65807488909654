import './ComponenetsStyles/PersonalInfo.css';
import '../../App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { userDataState } from '../../main';
import {
  defineSelectColor,
  formatPhoneNumber,
  handleGoogleMapsChange,
  handleInputchange,
  handleSelectChange,
  navigateWithQuery,
  validateEmail,
  validatePhone,
  validateZip,
} from '../../utils/utilFuntions';
import { updateClient, TPRXPatient } from '../../utils/sfFunctions';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-setup/firebase';
import ConfirmationIcon from '../../assets/images/ConfirmationIcon.svg';
import AutoComplete from 'react-google-autocomplete';

function PersonalInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDataChanged, setIsDataChanged] = useState(false);
  const { userData, setUserData } = useContext(userDataState);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  //select fields colors and default values
  useEffect(() => {
    if (userData !== null && !isDataUpdated) {
      defineSelectColor('employed', userData);
      defineSelectColor('marital', userData);
    }
  }, [userData, isDataUpdated]);
  const newPersonalData = useRef<TPRXPatient>({
    fname: userData?.fname,
    lname: userData?.lname,
    mname: userData?.mname,
    address: userData?.address,
    city: userData?.city,
    state: userData?.state,
    zip: userData?.zip,
    country: userData?.country,
    phone: userData?.phone,
    email: userData?.email,
    marital: userData?.marital,
    residency: userData?.residency || true,
    employed: userData?.employed,
    sex: userData?.sex,
    disabled: userData?.disabled,
    numinhouse: userData?.numinhouse,
    emerContactName: userData?.emerContactName,
    emerContactPhone: userData?.emerContactPhone,
    income: userData?.income,
  });

  async function updatePersonalInfo() {
    if (userData === null) return;
    setIsUpdatingData(true);
    const userDocRef = doc(db, 'clients', userData.ssn.toString());
    await setDoc(userDocRef, newPersonalData.current, { merge: true });
    await updateClient(newPersonalData.current).then(() => {
      setIsUpdatingData(false);
      setIsDataChanged(false);
      setUserData((prevData) => {
        return { ...prevData, ...newPersonalData.current };
      });
      setIsDataUpdated(true);
    });
  }

  return (
    <div className="container">
      <button
        className="back-btn"
        onClick={() => {
          navigateWithQuery(navigate, '/', location);
        }}>
        <svg
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.6049 5.18512H2.85895L5.63995 7.96612L4.82095 8.78512L0.563945 4.52812L4.82095 0.27112L5.63995 1.09012L2.86795 3.86212H13.6049V5.18512Z"
            fill="#191919"
          />
        </svg>
        Back
      </button>
      {isUpdatingData ? (
        <div className="loader-c">
          <div className="loader" />
        </div>
      ) : isDataUpdated ? (
        <>
          <div className="success-c">
            <img src={ConfirmationIcon} alt="success" />
            <h2>Personal Information has been updated</h2>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-center margin-bottom-64">Personal Information</h1>

          <div
            className="personal-info-c tprx-card"
            style={{ minHeight: '35.5rem' }}>
            <div className="inputfields-row">
              <div className="inputfield-c">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={userData!.email}
                  onChange={(e) => {
                    handleInputchange(
                      e,
                      userData,
                      setIsDataChanged,
                      validateEmail(e)
                    );
                    newPersonalData.current.email = e.target.value;
                  }}
                />
                <div className="error-message">
                  This is not a valid email address
                </div>
              </div>
              <div className="inputfield-c">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="input"
                  name="phone"
                  id="dayphone"
                  placeholder={userData!.phone}
                  onChange={(e) => {
                    formatPhoneNumber(e);
                    handleInputchange(
                      e,
                      userData,
                      setIsDataChanged,
                      validatePhone(e)
                    );
                    newPersonalData.current.phone = e.target.value;
                  }}
                />
                <div className="error-message">Must be 10 digits number</div>
              </div>
              <div className="inputfield-c">
                <label htmlFor="patientAddress">Address</label>
                <AutoComplete
                  apiKey={import.meta.env.VITE_GOOGLE_KEY}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleGoogleMapsChange(
                      e.target.value,
                      userData,
                      setIsDataChanged,
                      'patientAddress'
                    );
                    if (e.target.value === '') {
                      newPersonalData.current.address = '';
                      newPersonalData.current.city = '';
                      newPersonalData.current.state = '';
                      return;
                    }
                    newPersonalData.current.address = e.target.value;
                  }}
                  onPlaceSelected={(place) => {
                    place.address_components.forEach(
                      (component: {
                        types: unknown[];
                        long_name: string;
                        short_name: string;
                      }) => {
                        const componentType = component.types[0];
                        switch (componentType) {
                          case 'street_number': {
                            newPersonalData.current.address = `${component.long_name} `;
                            break;
                          }
                          case 'route': {
                            newPersonalData.current.address +=
                              component.short_name;
                            break;
                          }
                          case 'locality':
                            newPersonalData.current.city = component.long_name;
                            break;
                          case 'administrative_area_level_1': {
                            newPersonalData.current.state =
                              component.short_name;
                            break;
                          }
                        }
                      }
                    );
                  }}
                  options={{
                    componentRestrictions: { country: 'us' },
                    fields: ['address_components', 'formatted_address'],
                    types: ['address'],
                  }}
                  placeholder={userData!.patientAddress || 'Enter Address'}
                />

                <div className="error-message">
                  Please choose from suggestion
                </div>
              </div>
              <div className="inputfield-c">
                <label htmlFor="Zip">Zip</label>
                <input
                  type="input"
                  name="zip"
                  id="zip"
                  placeholder={userData!.zip}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, '');
                    if (e.target.value.length > 5) {
                      e.target.value = e.target.value.slice(0, 5);
                    }
                    handleInputchange(
                      e,
                      userData,
                      setIsDataChanged,
                      validateZip(e)
                    );
                    newPersonalData.current.zip = e.target.value;
                  }}
                />
                <div className="error-message">Must be a 5 digits number</div>
              </div>
            </div>
            <div className="inputfields-row">
              <div className="inputfield-c">
                <label htmlFor="employed">Employment Status</label>
                <select
                  name="employed"
                  id="employed"
                  onChange={(e) => {
                    handleSelectChange(e, userData, setIsDataChanged);
                    newPersonalData.current.employed = e.target.value;
                  }}>
                  <option value="Full Time">Full Time</option>
                  <option value="Part Time">Part Time</option>
                  <option value="Self-Employed">Self-Employed</option>
                  <option value="On Unemployment Checks">
                    On Unemployment Checks
                  </option>
                  <option value="Contract">Contract</option>
                  <option value="Seasonal">Seasonal</option>
                  <option value="Widowed">Retired</option>
                </select>
              </div>
              <div className="inputfield-c">
                <label htmlFor="marital">Martial Status</label>
                <select
                  name="marital"
                  id="marital"
                  onChange={(e) => {
                    handleSelectChange(e, userData, setIsDataChanged);
                    newPersonalData.current.marital = e.target.value;
                  }}>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                  <option value="Legally Separated">Legally Separated</option>
                  <option value="Civil Union">Civil Union</option>
                </select>
              </div>
              <div className="inputfield-c">
                <label htmlFor="income">Anuual Income</label>
                <input
                  type="input"
                  name="income"
                  id="income"
                  placeholder={
                    '$' + parseInt(userData!.income).toLocaleString()
                  }
                  onChange={(e) => {
                    // remove all non-numeric characters
                    e.target.value = e.target.value.replace(/\D/g, '');
                    if (e.target.value === '') return;
                    // add $ at the beginning and commas for thousands separator
                    e.target.value =
                      '$' + parseInt(e.target.value).toLocaleString();
                    handleInputchange(e, userData, setIsDataChanged);
                    const cleanValue = e.target.value
                      .split('$')[1]
                      .replace(/,/g, '');
                    newPersonalData.current.income = Number(cleanValue);
                  }}
                />
              </div>
            </div>
            <div className="inputfields-row">
              <div className="inputfield-c">
                <label htmlFor="numinhouse">People in household</label>
                <input
                  type="number"
                  name="numinhouse"
                  id="numinhouse"
                  placeholder={userData!.numinhouse.toString()}
                  onChange={(e) => {
                    handleInputchange(e, userData, setIsDataChanged);
                    newPersonalData.current.numinhouse = e.target.value;
                  }}
                />
              </div>
              <div className="inputfield-c">
                <label htmlFor="emerContactName">Emergancy Contact Name</label>
                <input
                  type="input"
                  name="emerContactName"
                  id="emerContactName"
                  placeholder={userData!.emerContactName}
                  onChange={(e) => {
                    handleInputchange(e, userData, setIsDataChanged);
                    newPersonalData.current.emerContactName = e.target.value;
                  }}
                />
              </div>
              <div className="inputfield-c">
                <label htmlFor="emerContactPhone">
                  Emergancy Contact phone
                </label>
                <input
                  type="input"
                  name="emerContactPhone"
                  id="emerContactPhone"
                  placeholder={userData!.emerContactPhone?.toString()}
                  onChange={(e) => {
                    formatPhoneNumber(e);
                    handleInputchange(
                      e,
                      userData,
                      setIsDataChanged,
                      validatePhone(e)
                    );
                    newPersonalData.current.emerContactPhone = e.target.value;
                  }}
                />
                <div className="error-message">Must be 10 digits number</div>
              </div>
            </div>
          </div>
          <div className="save-btn-c">
            <button
              className="cta-main save-btn"
              disabled={!isDataChanged}
              onClick={updatePersonalInfo}>
              Update
            </button>
          </div>
        </>
      )}
    </div>
  );
}
export default PersonalInfo;
