/* eslint-disable @typescript-eslint/no-explicit-any */
import { docInfo, orderInfo } from './types';

const values = JSON.parse(sessionStorage.getItem('formData') || '{}');
const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');

const getQueryparams = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  return searchParams;
};

// Helper function for Source field
function getSourceField(
  isCHC: boolean,
  isEnrollPrime: boolean,
  isPinnacle: boolean
): string {
  return `*Source:*\n${
    isCHC
      ? 'CHC'
      : isEnrollPrime
      ? 'EnrollPrime'
      : isPinnacle
      ? 'pinnacle'
      : 'website'
  }`;
}

// Helper function for Patient field
function getPatientField(patient: any): string {
  return `
*Patient:*
First Name: ${patient.fname}
Middle Name: ${patient.mname}
Last Name: ${patient.lname}
Apt: ${patient.apt}
Address: ${patient.fullAddress}
SSN: ${patient.ssn}
Birthday: ${patient.dob}
Email: ${patient.email}
Income: ${patient.income}`;
}

// Helper function for Insurance field
function getInsuranceField(insurance: any): string {
  return `
*Insurance:*
Company: ${insurance.company || '-'}
Product: ${insurance.product || '-'}
Plan: ${insurance.plan || '-'}
Policy: ${insurance.policy || '-'}`;
}

// Helper function for Medicare field
function getMedicareField(medicare: any): string {
  return `
*Medicare:*
Part D: ${medicare.partD || '-'}
Advantage: ${medicare.advantage || '-'}
Supplement: ${medicare.supplement || '-'}
Insurance Name: ${medicare.insuranceName || '-'}
Insurance Address: ${medicare.insuranceAddress || '-'}
Plan Name: ${medicare.planName || '-'}
Plan Phone: ${medicare.planPhone || '-'}
Group Number: ${medicare.groupNumber || '-'}
RxBIN: ${medicare.rxbin || '-'}
RxPCN: ${medicare.rxpcn || '-'}
RxGroup: ${medicare.rxgroup || '-'}
RxMember ID: ${medicare.rxmemberid || '-'}
Part Aid: ${medicare.partAid || '-'}
MBI: ${medicare.mbi || '-'}`;
}

// Helper function for Doctor section
function getDoctorFields(doctors: any[]): any[] {
  if (!doctors.length) return [];
  return doctors.map((doctor) => ({
    type: 'mrkdwn',
    text: `
*Doctor:*
First Name: ${doctor.fname}
Middle Name: ${doctor.mname}
Last Name: ${doctor.lname}
Phone: ${doctor.phone}
Fax: ${doctor.fax}
Address: ${doctor.fullAddress}, ${doctor.zip}, Suite: ${doctor.suite}
Facility: ${doctor.facility}`,
  }));
}

// Helper function for Order section
function getOrderFields(orders: any[]): any[] {
  if (!orders.length) return [];
  return orders.map((order) => ({
    type: 'mrkdwn',
    text: `
*Order:*
Doctor Name: ${order.doctorName}
Medication Name: ${order.medicationName}
Medication Strength: ${order.medicationStrength}`,
  }));
}

export async function sendLogToSlack(values: any, isNoMeds: boolean) {
  const isCHC = getQueryparams().get('source') === 'chc';
  const isEnrollPrime = getQueryparams().get('source') === 'enrollprime';
  const isPinnacle = getQueryparams().get('source') === 'pinnacle';

  const baseBlocks = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: 'New Application Submission Before Payment',
        emoji: true,
      },
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: getSourceField(isCHC, isEnrollPrime, isPinnacle),
        },
        {
          type: 'mrkdwn',
          text: `*Agent Details:*\n${values.patient.agentName} + ${values.patient.agentId}`,
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: getPatientField(values.patient),
        },
        {
          type: 'mrkdwn',
          text: getInsuranceField(values.patient.insurance),
        },
        {
          type: 'mrkdwn',
          text: getMedicareField(values.patient.medicare),
        },
      ],
    },
  ];

  // Additional blocks based on the `isNoMeds` flag
  const additionalBlocks = !isNoMeds
    ? [
        {
          type: 'divider',
        },
        {
          type: 'section',
          fields: getDoctorFields(values.doctors),
        },
        {
          type: 'divider',
        },
        {
          type: 'section',
          fields: getOrderFields(values.orders),
        },
      ]
    : [];

  const message = {
    blocks: [...baseBlocks, ...additionalBlocks],
  };

  try {
    const response = await fetch(
      'https://us-central1-transparent-rx.cloudfunctions.net/slackSubmission',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
}

export async function sendErrorToSlack(errorDetails: any) {
  if (errorDetails.environment === 'development') return;
  const message = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: '🚨 *Error Occurred* 🚨',
          emoji: true,
        },
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Message:*\n${errorDetails.message}`,
          },
        ],
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Stack:*\n${errorDetails.stack}`,
          },
          {
            type: 'mrkdwn',
            text: `*Environment:*\n${errorDetails.environment}`,
          },
          {
            type: 'mrkdwn',
            text: `*User Agent:*\n${errorDetails.userAgent}`,
          },
          {
            type: 'mrkdwn',
            text: `*Timestamp:*\n${new Date(
              errorDetails.timestamp
            ).toISOString()}`,
          },
          {
            type: 'mrkdwn',
            text: `*URL:*\n${errorDetails.url}`,
          },
        ],
      },
      {
        type: 'divider',
      },
    ],
  };

  if (values.patient) {
    message.blocks.push(
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: '*Source:*\nCHC',
          },
          {
            type: 'mrkdwn',
            text: `*Agent Details:*\n${values.patient.agentName} + ${values.patient.agentId}`,
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Patient:*\nFirst Name: ${values.patient.fname}\nMiddle Name: ${values.patient.mname}\nLast Name: ${values.patient.lname}\nApt: ${values.patient.apt}\nAddress: ${values.patient.fullAddress}\nSSN: ${values.patient.ssn}\nBirthday: ${values.patient.dob}\nEmail: ${values.patient.email}\nIncome: ${values.patient.income}`,
          },
          {
            type: 'mrkdwn',
            text: `*Insurance:*\nCompany: ${values.patient.insurance.company}\nProduct: ${values.patient.insurance.product}\nPlan: ${values.patient.insurance.plan}\nPolicy: ${values.patient.insurance.policy}`,
          },
          {
            type: 'mrkdwn',
            text: `*Medicare:*\nPart D: ${values.patient.medicare.partD}\nAdvantage: ${values.patient.medicare.advantage}\nSupplement: ${values.patient.medicare.supplement}\nInsurance Name: ${values.patient.medicare.insuranceName}\nInsurance Address: ${values.patient.medicare.insuranceAddress}\nPlan Name: ${values.patient.medicare.planName}\nPlan Phone: ${values.patient.medicare.planPhone}\nGroup Number: ${values.patient.medicare.groupNumber}\nRxBIN: ${values.patient.medicare.rxbin}\nRxPCN: ${values.patient.medicare.rxpcn}\nRxGroup: ${values.patient.medicare.rxgroup}\nRxMember ID: ${values.patient.medicare.rxmemberid}\nPart Aid: ${values.patient.medicare.partAid}\nMBI: ${values.patient.medicare.mbi}`,
          },
        ],
      },
      {
        type: 'divider',
      },

      ...values.doctors.map((doctor: docInfo) => ({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Doctor:*\nFirst Name: ${doctor.fname}\nMiddle Name: ${doctor.mname}\nLast Name: ${doctor.lname}\nPhone: ${doctor.phone}\nFax: ${doctor.fax}\nAddress: ${doctor.fullAddress}, ${doctor.zip}, suite:${doctor.suite}\nFacility: ${doctor.facility}`,
        },
      })),

      {
        type: 'divider',
      },
      ...values.orders.map((order: orderInfo) => ({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Order:*\nDoctor Name: ${order.doctorName}\nMedication Name: ${order.medicationName}\nMedication Strength: ${order.medicationStrength}`,
        },
      })),

      {
        type: 'divider',
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Agreements:*\nIncome Statement: ${values.agreements.incomeStatement.toString()}\nPolicies Statement: ${values.agreements.policiesStatement.toString()}\nInformation Statement: ${values.agreements.informationStatement.toString()}\nEffective Statement: ${values.agreements.effectiveStatement.toString()}`,
          },
        ],
      }
    );
  } else if (userData) {
    message.blocks.push({
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*user email: ${userData.email}`,
        },
        {
          type: 'mrkdwn',
          text: `*user uniqueId: ${userData.uniqueId}`,
        },
      ],
    });
  }
  try {
    const response = await fetch(
      'https://us-central1-transparent-rx.cloudfunctions.net/slackErrors',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  } catch (err) {
    console.log(err);
  }
}
