const formConfig = [
  {
    label: 'Personal Information',
    title: 'Please fill in your details below',
    fields: [
      { name: 'fname', label: 'First Name', type: 'text', required: true },
      { name: 'mname', label: 'Middle Name', type: 'text', required: false },
      { name: 'lname', label: 'Last Name', type: 'text', required: true },
      { name: 'dob', label: 'Date of Birth', type: 'date', required: true },
      {
        name: 'numinhouse',
        label: 'Number of people in household',
        type: 'number',
        required: true,
      },
      {
        name: 'income',
        label: 'Yearly Income',
        type: 'income',
        required: true,
      },
      {
        name: 'disabled',
        label:
          'Are you a person of disablities as determined by Social Security?',
        type: 'radio',
        required: true,
      },
      {
        name: 'agentName',
        label: 'Agent Full Name',
        type: 'text',
        required: false,
      },
      {
        name: 'agentId',
        label: 'Agent National Producer Number',
        type: 'text',
        required: false,
      },
    ],
    insurance: [
      {
        name: 'company',
        label: 'Insurance Company',
        type: 'select',
        required: true,
      },
      {
        name: 'product',
        label: 'Insurance Product',
        type: 'select',
        required: true,
      },
      {
        name: 'plan',
        label: 'Insurance Plan',
        type: 'select',
        required: true,
      },
      {
        name: 'policy',
        label: 'Insurance Policy Number',
        type: 'number',
        required: false,
      },

      {
        linkText: 'I can’t find the insurance product I have in the list',
        popupText:
          'Please call us at 1-800-555-5555 to speak with a representative who can help you enroll in the program.',
      },
    ],
    medicare: [
      {
        name: 'bothSSC',
        label: 'Do you have both Social Security and Medicare?',
        type: 'radio',
        required: true,
      },
      {
        name: 'partD',
        label: 'Are you on Medicare Part D?',
        type: 'radio',
        required: true,
      },
      {
        name: 'advantage',
        label: 'Are you on medicare Advantage?',
        type: 'radio',
        required: true,
      },
      {
        name: 'advantageCoverage',
        label: 'Do you have Medicare Advantage Coverage?',
        type: 'radio',
        required: true,
      },
      {
        name: 'supplement',
        label: 'Do you have Medicare Supplement?',
        type: 'radio',
        required: true,
      },
      {
        name: 'insuranceName',
        label: 'Insurance Name',
        type: 'text',
        required: false,
      },
      {
        name: 'insuranceAddress',
        label: 'Address of insurance Company',
        type: 'text',
        required: false,
      },
      {
        name: 'planName',
        label: 'Plan Name',
        type: 'text',
        required: false,
      },
      {
        name: 'planPhone',
        label: 'Plan Phone',
        type: 'phone',
        required: false,
      },
      {
        name: 'groupNumber',
        label: 'Group#',
        type: 'text',
        required: false,
      },
      {
        name: 'rxbin',
        label: 'RxBIN#',
        type: 'text',
        required: false,
      },
      {
        name: 'rxpcn',
        label: 'RxPCN#',
        type: 'text',
        required: false,
      },
      {
        name: 'rxgroup',
        label: 'RxGroup#',
        type: 'text',
        required: false,
      },
      {
        name: 'rxmemberid',
        label: 'Rx Member ID',
        type: 'text',
        required: false,
      },
      {
        name: 'partAid',
        label: 'Medicare Part A ID#',
        type: 'text',
        required: false,
      },

      {
        name: 'mbi',
        label: 'MBI#',
        type: 'text',
        required: false,
      },
      {
        linkText: 'I don’t have Medicare coverage',
        popupText:
          'Please call us at 1-800-555-5555 to speak with a representative who can help you enroll in the program.',
      },
    ],
    isValidStep: true,
  },
  {
    label: 'Medications',
    title: 'What Medication do you take?',
    fields: [
      {
        name: 'choose-med',
        label: 'Medication Name',
        type: 'select',
        required: true,
      },
      {
        name: 'medicationStrength',
        label: 'Medication Strength',
        type: 'select',
        required: true,
      },
      {
        name: 'cost',
        label: 'Medication Cost',
        type: 'info',
      },
      { name: 'choose-med-2', label: 'Medication Name', type: 'select' },
      {
        name: 'medicationStrength-2',
        label: 'Medication Strength',
        type: 'select',
      },
      {
        name: 'cost-2',
        label: 'Medication Cost',
        type: 'info',
      },
      { name: 'choose-med-3', label: 'Medication Name', type: 'select' },
      {
        name: 'medicationStrength-3',
        label: 'Medication Strength',
        type: 'select',
      },
      {
        name: 'cost-3',
        label: 'Medication Cost',
        type: 'info',
      },
      { name: 'choose-med-4', label: 'Medication Name', type: 'select' },
      {
        name: 'medicationStrength-4',
        label: 'Medication Strength',
        type: 'select',
      },
      {
        name: 'cost-4',
        label: 'Medication Cost',
        type: 'info',
      },
    ],
  },
  {
    label: 'Personal details',
    title: 'Please fill in your details below',
    fields: [
      {
        name: 'ssn',
        label: 'Social Security Number',
        type: 'text',
        required: true,
      },
      {
        name: 'sex',
        label: 'Gender',
        type: 'radio',
        options: ['Male', 'Female'],
        required: true,
      },
      {
        name: 'marital',
        label: 'Marital Status',
        type: 'select',
        options: [
          'Single',
          'Married',
          'Divorced',
          'Widowed',
          'Legally Separated',
          'Civil Union',
        ],
        required: true,
      },
      {
        name: 'employed',
        label: 'Employment Status',
        type: 'select',
        options: [
          'Full Time',
          'Part Time',
          'Self-Employed',
          'On Unemployment Checks',
          'No Income in Household',
          'Contract',
          'Seasonal',
          'Retired',
        ],
        required: true,
      },
      {
        name: 'onSocialSecurityChecks',
        label: 'Are you on social security checks?',
        type: 'securityCheck',
        options: ['Yes', 'No'],
        required: true,
      },
      { name: 'email', label: 'Email', type: 'email', required: true },
      { name: 'phone', label: 'Phone Number', type: 'phone', required: true },
      { name: 'address', label: 'Address', type: 'address', required: true },
      { name: 'zip', label: 'Zip', type: 'text', required: true },
      { name: 'apt', label: 'Apt / Suite', type: 'text', required: false },
      {
        name: 'emerContactName',
        label: 'Alternate contact Name',
        type: 'text',
        required: true,
      },
      {
        name: 'emerContactPhone',
        label: 'Alternate contact Phone',
        type: 'phone',
        required: true,
      },
    ],
  },
  {
    label: 'Healthcare Providers',
    title: 'Please fill in your Healthcare providers details below',
    fields: [
      { name: 'fname', label: 'First Name', type: 'text', required: true },
      {
        name: 'mname',
        label: 'Middle Name',
        type: 'text',
        required: false,
      },
      { name: 'lname', label: 'Last Name', type: 'text', required: true },
      {
        name: 'phone',
        label: 'Phone Number',
        type: 'phone',
        required: true,
      },
      {
        name: 'facility',
        label: 'Facility Name',
        type: 'text',
        required: true,
      },
      {
        name: 'address',
        label: 'Address',
        type: 'address',
        required: true,
      },
      {
        name: 'suite',
        label: 'Suite',
        type: 'text',
        required: false,
      },
      {
        name: 'zip',
        label: 'Zip',
        type: 'number',
        required: true,
      },
      {
        name: 'fax',
        label: 'Fax Number',
        type: 'phone',
        required: true,
      },
      {
        name: 'faxValidate',
        label: 'Verify Fax Number',
        type: 'phone',
        required: true,
      },

      {
        name: 'prescribedMed',
        label: 'Prescribed Medication',
        type: 'select',
        required: true,
      },
    ],
  },
];

export default formConfig;
